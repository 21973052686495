import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import Moment from "react-moment";
import { useStoreState, useStoreActions } from "easy-peasy";
import { useNavigate } from "react-router-dom";
import "./../rewardMessage/rewardmessage.css";
import { read_cookie } from "../../read_cookie";
import DOMPurify from "dompurify";

import config from "./../../configs/config";
import homeData from "./../../data/home.json";

const RenderHtmlElem = (props) => {
  return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.msgBody) }}></div>;
};

const onClickMessage = (e) => {
  e.preventDefault();
};

const MessageDetails = (props) => {
  const getMessagesac = useStoreActions((actions) => actions.messages.getMessages);
  const getMessagesValue = useStoreState((state) => state.messages.messagesInfo);
  let navigate = useNavigate();
  document.title = props.title;
  let params = useParams();
  let [messageDetail, setMessageDetail] = useState([]);
  let [msgBody, setMsgBody] = useState("");
  let [count, setcount] = useState(0);
  let [msgIndex, setmsgIndex] = useState(0);
  const [loading, setloading] = useState(false);
  const [togglePopup, settogglePopup] = useState(false);
  const [msgDate, setmsgDate] = useState("");
  const [sortedGetMessagesValue, setSortedGetMessagesValue] = useState([]);
  const pdfGenerate = () => {
    var divContents = document.querySelector("#capture").innerHTML;
    var printWindow = window.open("", "", "height=600,width=800");
    printWindow.document.write(
      DOMPurify.sanitize(
        "<html><head><title>" + messageDetail.Subject.split(" ").join("") + "</title>"
      )
    );
    printWindow.document.write("</head><body >");
    printWindow.document.write(divContents);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };

  const goto = (act) => {
    let harId;
    if (act === "next") {
      harId = msgIndex;
    } else {
      harId = msgIndex - 2;
    }
    let currHarmonyId = sortedGetMessagesValue[harId].HarmonyMessageId;
    navigate(`/MyProfile/Messaging/messageDetails/harmony/${currHarmonyId}`);
    window.location.reload(false);
  };

  const msgdetail = () => {
    setloading(true);

    const URL = config.qa.node_url;

    let ProfileId = read_cookie("profileId");
    let apiUrl = `invoke`;
    let requestData = {
      ProfileId,
      HarmonyMessageId: params.id,
      "Program-Code": "RTL_PNT",
    };
    let header = { script: "RenderEmail", token: sessionStorage.accessToken };
    axios
      .post(URL + apiUrl, requestData, {
        headers: header,
      })
      .then(function (response) {
        if (response.data) {
          setMessageDetail(response.data);
          var bodyHtml = /<body.*?>([\s\S]*)<\/body>/.exec(response.data.Body)[1];
          setMsgBody(bodyHtml);
        }
        setloading(false);
        getMessagesac(sessionStorage.getItem("accessToken"));
      })
      .catch(function (error) {
        console.error(error);
        setloading(false);
      });
  };
  useEffect(() => {
    msgdetail();
  }, []);

  if (getMessagesValue && getMessagesValue.length > 1 && count === 0) {
    let msgInd = 0;
    var sortedData = getMessagesValue.sort(function (a, b) {
      return new Date(FormateDate(b.SendDate)) - new Date(FormateDate(a.SendDate));
    });
    setSortedGetMessagesValue(sortedData);
    sortedData.forEach((element, index) => {
      if (element.HarmonyMessageId == params.id) {
        msgInd = index + 1;
        setmsgDate(FormateDate(element.SendDate));
      }
    });
    function FormateDate(d) {
      d = d.replace(/-/g, "/");

      if (d.slice(3, 6) == "JAN") {
        d = d.replace("JAN", "01");
      } else if (d.slice(3, 6) == "FEB") {
        d = d.replace("FEB", "02");
      } else if (d.slice(3, 6) == "MAR") {
        d = d.replace("MAR", "03");
      } else if (d.slice(3, 6) == "APR") {
        d = d.replace("APR", "04");
      } else if (d.slice(3, 6) == "MAY") {
        d = d.replace("MAY", "05");
      } else if (d.slice(3, 6) == "JUN") {
        d = d.replace("JUN", "06");
      } else if (d.slice(3, 6) == "JUL") {
        d = d.replace("JUL", "07");
      } else if (d.slice(3, 6) == "AUG") {
        d = d.replace("AUG", "08");
      } else if (d.slice(3, 6) == "SEP") {
        d = d.replace("SEP", "09");
      } else if (d.slice(3, 6) == "OCT") {
        d = d.replace("OCT", "10");
      } else if (d.slice(3, 6) == "NOV") {
        d = d.replace("NOV", "11");
      } else if (d.slice(3, 6) == "DEC") {
        d = d.replace("DEC", "12");
      }
      const date = d.slice(0, 6) + "20" + d.slice(6);
      const formatedDate =
        date.slice(3, 5) + "/" + date.slice(0, 2) + date.slice(5, 7) + date.slice(7);

      return formatedDate;
    }

    let unreadArr = sortedData.filter((msg) => {
      return msg.IsRead === "N";
    });
    localStorage.unreadMsgCount = unreadArr.length;
    setmsgIndex(msgInd);
    setcount(1);
  }

  const setPopupState = () => {
    settogglePopup(!togglePopup);
  };

  return (
    <div className="container">
      {loading ? <div className="loader"></div> : false}

      <div className="wrap-message-detail">
        <div className="row">
          <div className="col-md-12 col-lg-12 pagination-wrap">
            <h3 role="heading" aria-level="1">
              Message Details
            </h3>
            <div className="pnc-mD-pagination">
              {getMessagesValue && getMessagesValue.length > 1 ? (
                <span>
                  {msgIndex === 1 ? (
                    <a href="" className="disabled" onClick={onClickMessage}>
                      <img src="/images/circlearrow-left.svg" alt="left-arrow" />
                    </a>
                  ) : (
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        goto();
                      }}
                    >
                      <img src="/images/circlearrow-left.svg" alt="left-arrow" />
                    </a>
                  )}

                  <span className="pagination-numbers">
                    {msgIndex} of {getMessagesValue.length}
                  </span>
                  {msgIndex === getMessagesValue.length ? (
                    <a href="" className="disabled" onClick={onClickMessage}>
                      <img src="/images/circlearrow.svg" alt="right-arrow" />
                    </a>
                  ) : (
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        goto("next");
                      }}
                    >
                      <img src="/images/circlearrow.svg" alt="right-arrow" />
                    </a>
                  )}
                </span>
              ) : (
                false
              )}
            </div>
          </div>
          <div className="col-md-12 col-lg-12">
            <div className="pnc-mD-print float-right">
              <a
                href=""
                onClick={(e) => {
                  e.preventDefault();
                  pdfGenerate();
                }}
              >
                <img className="pnc-print" src="/images/icon-print.svg" alt="print" />
              </a>
            </div>
          </div>
        </div>
        <div className="row pnc-customer-service">
          <div className="col-md-12 col-lg-4">
            <p>
              <strong className="label-from">From:</strong> {homeData.messageSenderTitle}
              <br />
              {msgDate !== "" ? <Moment format="MM/DD/YYYY hh:mm A">{msgDate}</Moment> : ""}
            </p>
          </div>
          <div className="col-md-12 col-lg-7">
            <p>{messageDetail.Subject}</p>
          </div>
          <br />
          <br />
          {messageDetail.Body ? (
            <div className="col-md-12 col-lg-8" id="capture">
              <RenderHtmlElem msgBody={msgBody} />
            </div>
          ) : (
            false
          )}
        </div>
        <Link className="btn cancel pull-right" to="/MyProfile/Messaging" aria-label="Messages">
          Back to Messages
        </Link>
      </div>
    </div>
  );
};

export default MessageDetails;
