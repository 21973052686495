import React, { useEffect, useRef } from "react";
import axios from "axios";
import { useStoreState } from "easy-peasy";
import config from "../configs/config";

import DOMPurify from "dompurify";

const SAML = () => {
  const getUserValue = useStoreState((state) => state.user.userInfo);
  const urlParams = new URLSearchParams(window.location.search);
  const SAMLRequest = urlParams.get("SAMLRequest");
  const RelayState = urlParams.get("RelayState");

  const formRef = useRef(null);
  const samlResponseRef = useRef(null);
  const relayStateRef = useRef(null);

  const fetchSamlData = async () => {
    try {
      const { data } = await axios.post(
        `${config.qa.node_url}b2rdecrypt`,
        { SAMLRequest, RelayState, getUserValue },
        { headers: { programcode: "RTL_PNT", token: sessionStorage.accessToken } }
      );

      if (data?.SAMLResponse) {
        formRef.current.action = DOMPurify.sanitize(data.url);
        samlResponseRef.current.value = data.SAMLResponse;
        relayStateRef.current.value = RelayState;
        formRef.current.submit();
      }
    } catch (error) {
      console.error("Error fetching SAML data:", error);
    }
  };

  useEffect(() => {
    if (getUserValue) {
      fetchSamlData();
    }
  }, [getUserValue]);

  return (
    <>
      <div className="loader"></div>
      <section className="pnc-help-section d-none">
        <div className="container">Loading...</div>
        <form
          ref={formRef}
          id="samlForm"
          name="samlForm"
          encType="application/x-www-form-urlencoded"
          method="post"
        >
          <div>
            <textarea ref={samlResponseRef} name="SAMLResponse" />
          </div>
          <br />
          <div>
            <textarea ref={relayStateRef} name="RelayState" />
          </div>
          <input type="submit" value="Submit" />
        </form>
      </section>
    </>
  );
};

export default SAML;
