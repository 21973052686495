import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { delete_cookie } from "../../read_cookie";

const Logout = (props) => {
  let navigate = useNavigate();
  delete_cookie("pnc-rewards-token");
  delete_cookie("profileId");
  delete_cookie("ProgramCode");
  sessionStorage.removeItem("navigationHistory")
  useEffect(() => {
    // sessionStorage.clear();
    // localStorage.clear();
    navigate(`/`); 
  }, [navigate]);

  return (
    <section className="pnc-help-section">
      <div className="loader"></div>
    </section>
  );
};

export default Logout;
