import React, { useEffect, useState } from "react";
import axios from "axios";
import Moment from "react-moment";
import Pagination from "react-js-pagination";
import Ordermodal from "./../modalpopups/ordermodal";
import { read_cookie } from "../../read_cookie";

import config from "./../../configs/config";

export const inclusiveRange = (start, end, step) => {
  return Array.from(
    Array.from(Array(Math.ceil(end / step)).keys()),
    (x) => start + x * step
  );
};

const OrderHistory = (props) => {
  document.title = props.title;
  const [indorder, setindOrder] = useState("");
  const [togglePopup, settogglePopup] = useState(false);
  const [loading, setloading] = useState(false);
  let [orders, setOrders] = useState([]);
  let [ordersIntial, setOrdersIntial] = useState([]);
  let [records, setRecords] = useState(10);
  let [recordsArr, setRecordsArr] = useState([10]);
  let [currStatus, setcurrStatus] = useState("All");
  let [currFrom, setcurrFrom] = useState(7);
  let [mon, setMon] = useState([]);
  const defaultStatusArr = ["All"];
  const statusArrDefault = [
    "All",
    "Ready For Pickup",
    "Placed",
    "Backordered",
    "Delivered",
    "Ordered",
    "Shipped",
    "Returned",
    "Cancelled",
    "Completed",
    "Returned",
    "Denied",
    "On Hold",
    "Rejected",
    "Finished",
  ];
  let [statusArr, setstatusArr] = useState(statusArrDefault);
  let [activePage, setactivePage] = useState(1);
  let [startCount, setStartCount] = useState(0);
  let [sort, setsort] = useState("down");
  const updateRecords = (e) => {
    setRecords(e.target.value);
    handlePageChange(1);
  };

  const mlist = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  var month_name = function (dt) {
    return mlist[dt.getMonth()];
  };


  const handlePageChange = (pagenum) => {
    setactivePage(pagenum);
    let stCount = pagenum * records - records;
    // let endCount = (pagenum * records)+1;
    // console.log(stCount,records,trans.slice(stCount,(stCount+records)),(stCount+records))
    setStartCount(stCount);
  };

  const sortDataDate = (des) => {
    if (!des) {
      if (sort === "downDate") {
        orders.sort((a, b) => new Date(a.OrderDate) - new Date(b.OrderDate));
        setsort("upDate");
      } else {
        orders.sort((a, b) => new Date(b.OrderDate) - new Date(a.OrderDate));
        setsort("downDate");
      }
    } else {
      orders.sort((a, b) => new Date(a.OrderDate) - new Date(b.OrderDate));
      setsort("upDate");
    }
  };

  const sortDataQty = (des) => {
    if (!des) {
      if (sort === "downQty") {
        orders.sort((a, b) => a.OrderQuantity - b.OrderQuantity);
        setsort("upQty");
      } else {
        orders.sort((a, b) => b.OrderQuantity - a.OrderQuantity);
        setsort("downQty");
      }
    } else {
      orders.sort((a, b) => a.OrderQuantity - b.OrderQuantity);
      setsort("upQty");
    }
  };

  const sortDataOrder = (des) => {
    if (!des) {
      if (sort === "downOrder") {
        orders.sort((a, b) => a.OrderNumber.localeCompare(b.OrderNumber));
        setsort("upOrder");
      } else {
        orders.sort((a, b) => b.OrderNumber.localeCompare(a.OrderNumber));
        setsort("downOrder");
      }
    } else {
      orders.sort((a, b) => a.OrderNumber.localeCompare(b.OrderNumber));
      setsort("upOrder");
    }
  };

  const sortDataRewards = (des) => {
    if (!des) {
      if (sort === "downRewards") {
        orders.sort((a, b) =>
          a.ItemDescription.localeCompare(b.ItemDescription)
        );
        setsort("upRewards");
      } else {
        orders.sort((a, b) =>
          b.ItemDescription.localeCompare(a.ItemDescription)
        );
        setsort("downRewards");
      }
    } else {
      orders.sort((a, b) => a.ItemDescription.localeCompare(b.ItemDescription));
      setsort("upRewards");
    }
  };

  const sortDataStatus = (des) => {
    if (!des) {
      if (sort === "downStatus") {
        orders.sort((a, b) => a.OrderStatus.localeCompare(b.OrderStatus));
        setsort("upStatus");
      } else {
        orders.sort((a, b) => b.OrderStatus.localeCompare(a.OrderStatus));
        setsort("downStatus");
      }
    } else {
      orders.sort((a, b) => a.OrderStatus.localeCompare(b.OrderStatus));
      setsort("upStatus");
    }
  };

  const sortDataPoints = (des) => {
    if (!des) {
      if (sort === "downPoints") {
        orders.sort((a, b) => a.Points - b.Points);
        setsort("upPoints");
      } else {
        orders.sort((a, b) => b.Points - a.Points);
        setsort("downPoints");
      }
    } else {
      orders.sort((a, b) => a.Points - b.Points);
      setsort("upPoints");
    }
  };

  const sortDataSplit = (des) => {
    if (!des) {
      if (sort === "downSplit") {
        orders.sort((a, b) => a.SplitPay - b.SplitPay);
        setsort("upSplit");
      } else {
        orders.sort((a, b) => b.SplitPay - a.SplitPay);
        setsort("downSplit");
      }
    } else {
      orders.sort((a, b) => a.SplitPay - b.SplitPay);
      setsort("upSplit");
    }
  };

  window.onload = function () {
    // sortData();
  };

  const openModal = (order) => {
    settogglePopup(true);
    setindOrder(order);
  };

  const setPopupState = () => {
    settogglePopup(!togglePopup);
  };

  const getDate = (days) => {
    let today = new Date();
    let lastWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - days
    );
    return lastWeek;
  };

  const filterByStatus = (e) => {
    let currFilter = e.target.value;
    setcurrStatus(currFilter);
    updateRec(currFilter);
  };

  const filterByDate = (e) => {
    let currFilter = e.target.value;
    setcurrFrom(currFilter);
    updateRec(null, currFilter);
  };

  const updateRec = (status, from) => {
    handlePageChange(1);
    status = status ? status : currStatus;
    from = from ? from : currFrom;
    if (status === "All" && from === "all") {
      setOrders(ordersIntial);
    } else if (status !== "All" && from === "all") {
      let filterdOrders = ordersIntial.filter((order) => {
        return order.OrderStatus.toLowerCase() === status.toLowerCase();
      });
      setOrders(filterdOrders);
    } else if (status !== "All" && from !== "all") {      
      let fromInt = parseInt(from);
      if(fromInt){
        let filterdOrders = ordersIntial.filter((order) => {
          return (
            order.OrderStatus.toLowerCase() === status.toLowerCase() &&
            new Date(order.OrderDate) > getDate(fromInt)
          );
        });
        setOrders(filterdOrders);
      }else{
        let filterdOrders = ordersIntial.filter((order) => {
          var orderDt = new Date(order.TransactionDateTime);
          var orderYear = orderDt.getFullYear();
          return (
            order.OrderStatus.toLowerCase() === status.toLowerCase() &&
            `${month_name(new Date(order.OrderDate))}/${orderYear}` === from
          );
        });
        setOrders(filterdOrders);
      }      
    } else if (status === "All" && from !== "all") {
      let fromInt = parseInt(from);
      if(fromInt){
        let filterdOrders = ordersIntial.filter((order) => {
          return new Date(order.OrderDate) > getDate(fromInt);
        });
        setOrders(filterdOrders);
      }else{
        let filterdOrders = ordersIntial.filter((order) => {
          var orderDt = new Date(order.TransactionDateTime);
          var orderYear = orderDt.getFullYear();          
          return `${month_name(new Date(order.OrderDate))}/${orderYear}` === from
        });
        setOrders(filterdOrders); 
      }

    }
    sortDataDate("des");
  };

  useEffect(() => {
    setloading(true);
    const URL = config.qa.node_url;
    let ProfileId = read_cookie("profileId");
    let apiUrl = `orderhistory`;
    let requestData = {
      ProfileId,
    };
    let header = { programcode: "RTL_PNT", token: sessionStorage.accessToken };
    axios
      .post(URL + apiUrl, requestData, {
        headers: header,
      })
      .then(function (response) {
        setloading(false);
        if (response.data) {
          let resp = [...response.data];
          let resultArr = resp.map((el) => {
            el.ItemDescription = el.ItemDescription.replace(
              /PNT|TVL|ONX/gi,
              ""
            );
            return el;
          });
         // setOrders(resultArr);
          let filterdOrders = resultArr.filter((order) => {
            return new Date(order.OrderDate) > getDate(currFrom);
          });
          setOrders(filterdOrders);
          setOrdersIntial(resultArr);
          let numberOfRecords = response.data.length > 0 ? response.data.length : 1;
          setRecordsArr(inclusiveRange(10, numberOfRecords, 10));
          const unique = [
            ...new Set(response.data.map((item) => item.OrderStatus)),
          ];
          const uniqueArr = defaultStatusArr.concat(unique);
          setstatusArr(uniqueArr);
        }
      })
      .catch(function (error) {
        console.error(error);
        setloading(false);
        // error;
      });
      let currMonth = new Date().getMonth();
      let currYear = new Date().getFullYear();
      // let currMonth = 11;
      switch (currMonth) {
        case 0:
          setMon([`${mlist[currMonth]}/${currYear}`, `${mlist[11]}/${currYear-1}`, `${mlist[10]}/${currYear-1}`]);
          break;
        case 1:
          setMon([`${mlist[currMonth]}/${currYear}`, `${mlist[0]}/${currYear}`, `${mlist[11]}/${currYear-1}`]);
          break;
        default:
          setMon([
            `${mlist[currMonth]}/${currYear}`,
            `${mlist[currMonth - 1]}/${currYear}`,
            `${ mlist[currMonth - 2]}/${currYear}`,
          ]);
      }
  }, []);
  const FormateDate = (d) => {
    const date = new Date(d)
    const formatedDate=date.toLocaleString('en-US',{year: 'numeric', month: '2-digit', day: '2-digit'  });
    return formatedDate;
  }
  return (
    <div className="container">
      {loading ? <div className="loader"></div> : false}
      {togglePopup ? (
        <Ordermodal
          order={indorder}
          togglePopup={togglePopup}
          setPopupState={setPopupState}
        />
      ) : (
        false
      )}
      <div className="wrap-order">
        <div className="row">
          <div className="col-12 offset-md-2 col-md-8 offset-lg-0 col-lg-12 heading-with-filter">
            <h3 role="heading" aria-level="1">
              Order History
            </h3>
            <span className="sort float-right">
              <a className="sort-inactive" href="#">
                <img
                  className="sort-img"
                  src="/images/sort-filter.svg"
                  alt="sort-filter"
                />
                Sort/Filter
              </a>
              <a className="sort-expand" href="#">
                <img
                  className="close-img"
                  src="/images/close-button.svg"
                  alt="close"
                />
                Close
              </a>
            </span>
          </div>
          <div className="col-12 offset-md-2 col-md-8 offset-lg-0 col-lg-12 pagination-wrap">
            <div className="float-right view-per-page-sec">
              <div className="d-flex view-per-page-sec-inner">
                <span>View</span>

                <select
                  id="filter"
                  className="form-control selectBox"
                  onChange={updateRecords}
                  role="listbox"
                  aria-label="selectbox-pagination"
                >
                  {recordsArr.slice(0,10).map((rec, index) => {
                    return (
                      <option value={rec} key={index}>
                        {rec}
                      </option>
                    );
                  })}
                </select>
                <span>Per page</span>
              </div>
            </div>
            <div className="d-flex pnc-transaction-pagination float-right">
              <div className="month-filter">
                <span>Show</span>
                <select
                  className="form-control selectBox"
                  role="listbox"
                  aria-label="selectbox-filter"
                  onChange={filterByStatus}
                >
                  {statusArr.map((status, index) => {
                    return (
                      <option value={status} key={index}>
                        {status}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="card-filter">
                <span>From</span>
                <select
                  className="form-control selectBox"
                  role="listbox"
                  aria-label="selectbox-sort"
                  onChange={filterByDate}
                >                
                  <option value="7">Last Week</option>
                  <option value="30">Last 30 Days</option>
                  <option value="all">All Orders </option>
                  {/* {mon.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item.split('/')[0]}
                      </option>
                    );
                  })} */}
                </select>
              </div>
            </div>
          </div>
          <div className="col-12 offset-md-2 col-md-8 offset-lg-0 col-lg-12">
            <div className="pnc-sort-mb">
              <p>FILTER BY</p>
              <select
                id="filter-mb"
                className="form-control selectBox"
                role="listbox"
                aria-label="selectbox-filter"
                onChange={filterByStatus}
              >
                {statusArr.map((status, index) => {
                  return (
                    <option value={status} key={index}>
                      {status}
                    </option>
                  );
                })}
              </select>
              <label>From</label>
              <select
                id="filter-mb"
                className="form-control selectBox"
                role="listbox"
                aria-label="selectbox-sort"
                onChange={filterByDate}
              >
                <option value="7">Last Week</option>
                <option value="30">Last 30 Days</option>
                <option value="all">All Orders </option>
                {/* {mon.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item.split('/')[0]}
                      </option>
                    );
                  })} */}
              </select>
              {/* <p>SORT BY</p>
              <select
                id="filter-mb"
                className="form-control selectBox"
                onChange={updateRecords}
                role="listbox"
                aria-label="selectbox-pagination"
              >
                {recordsArr.slice(0,10).map((rec, index) => {
                  return (
                    <option value={rec} key={index}>
                      {rec}
                    </option>
                  );
                })}
              </select> */}
              <button type="submit" className="btn apply-filter">
                Apply Filter
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 offset-md-2 col-md-8 offset-lg-0 col-lg-12">
            <div className="pnc-order-wrapper">
              <div className="d-flex pnc-order-table">
                <div
                  className="p-3 col-lg-2"
                  onClick={() => {
                    sortDataDate();
                  }}
                >
                  Date&nbsp;
                  {sort === "upDate" ? (
                    <i className={"fa fa-sort-up"}></i>
                  ) : (
                    <i className={"fa fa-sort-down"}></i>
                  )}
                </div>
                <div
                  className="p-3 col-lg-1"
                  onClick={() => {
                    sortDataQty();
                  }}
                >
                  Qty&nbsp;
                  {sort === "upQty" ? (
                    <i className={"fa fa-sort-up"}></i>
                  ) : (
                    <i className={"fa fa-sort-down"}></i>
                  )}
                </div>
                <div
                  className="p-3 col-lg-3"
                  onClick={() => {
                    sortDataOrder();
                  }}
                >
                  Order#&nbsp;
                  {sort === "upOrder" ? (
                    <i className={"fa fa-sort-up"}></i>
                  ) : (
                    <i className={"fa fa-sort-down"}></i>
                  )}
                </div>
                <div
                  className="p-3 col-lg-3 text-lg-center"
                  onClick={() => {
                    sortDataRewards();
                  }}
                >
                  Rewards Redeemed&nbsp;
                  {sort === "upRewards" ? (
                    <i className={"fa fa-sort-up"}></i>
                  ) : (
                    <i className={"fa fa-sort-down"}></i>
                  )}
                </div>
                <div
                  className="p-3 col-lg-1"
                  onClick={() => {
                    sortDataStatus();
                  }}
                >
                  Status&nbsp;
                  {sort === "upStatus" ? (
                    <i className={"fa fa-sort-up"}></i>
                  ) : (
                    <i className={"fa fa-sort-down"}></i>
                  )}
                </div>
                <div
                  className="p-3 col-lg-1"
                  onClick={() => {
                    sortDataPoints();
                  }}
                >
                  Points&nbsp;
                  {sort === "upPoints" ? (
                    <i className={"fa fa-sort-up"}></i>
                  ) : (
                    <i className={"fa fa-sort-down"}></i>
                  )}
                </div>
                <div
                  className="p-3 col-lg-1"
                  onClick={() => {
                    sortDataSplit();
                  }}
                >
                  Split pay&nbsp;
                  {sort === "upSplit" ? (
                    <i className={"fa fa-sort-up"}></i>
                  ) : (
                    <i className={"fa fa-sort-down"}></i>
                  )}
                </div>
              </div>
              {orders.length > 0 ? (
                orders
                  .slice(startCount, startCount + records)
                  .map((order, index) => {
                    return (
                      <a
                        className="d-flex flex-wrap mAlernate"
                        href=""
                        key={index}
                        onClick={(e) => { e.preventDefault(); openModal(order) }}
                      >
                        <span className="p-3 col-8 col-md-9 col-lg-2 mdateAlignment">
                        {FormateDate(order.OrderDate)}
                          {/* <Moment format="MM/DD/YYYY">{order.OrderDate}</Moment> */}
                        </span>
                        <span className="p-3 col-md-2 col-lg-1">
                          {order.OrderQuantity}
                        </span>
                        <span className="p-3 col-12 col-md-12 col-lg-3 d-none d-lg-block lh18">
                          {
                          order.OrderNumber
                          }
                        </span>
                        <span
                          className="p-3 col-12 col-md-12 col-lg-3 text-lg-center"
                          data-toggle="modal"
                          data-target="#pncModal-orders"
                        >
                          {order.ItemDescription}
                        </span>
                        <span className="p-3 col-4 col-md-3 col-lg-1 mdateStatus">
                          {order.OrderStatus}
                        </span>
                        <span className="p-3 col-4 col-md-6 col-lg-1 mPoints">
                          {order.Points !== ""
                            ? parseInt(order.Points).toLocaleString()
                            : ""}
                        </span>
                        <div className="p-3 col-lg-1 d-none d-lg-block">
                          {order.SplitPay !== ""
                            ? `$${parseFloat(order.SplitPay.toLocaleString())}`
                            : ""}
                        </div>
                      </a>
                    );
                  })
              ) : (
                <div className="d-flex flex-wrap mAlernate">
                  <span className="p-3 col-12 col-md-12 col-lg-12 mdateAlignment">
                    {!loading ? "You have no order history" : ""}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="float-right view-per-page-sec">
          <div className="d-flex view-per-page-sec-inner">
            <Pagination
              activePage={activePage}
              itemsCountPerPage={records}
              totalItemsCount={orders.length}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              hideDisabled={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderHistory;
