import React from "react";

import RewardsBenefits from "../rewardsBenefits/rewardsBenefits";
import TravelProtection from "../travelProtection/travelProtection";
import AdditionalBenefits from "../additionalBenefits/additionalBenefits";

const Benefits = (props) => {
  document.title = props.title;
  return (
    <>
      <RewardsBenefits />
      <TravelProtection />
      <AdditionalBenefits />
    </>
  );
};

export default Benefits;
