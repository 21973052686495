import React from "react";
import Card from "react-bootstrap/Card";
import "./getRewarded.css";
import data from "./../../data/getRewarded.json";
import DOMPurify from "dompurify";

const GetRewarded = () => {
  const text = data[0];
  const cardData = data[1];
  return (
    <section
      className="pnc-get-rewarded"
      aria-labelledby="pnc-get-rewarded-section-heading"
    >
      <div className="container">
        <div className="row pnc-get-rewarded-content-row">
          <div className="col-12">
            <div id="pnc-get-rewarded-section-heading">{text.Heading}</div>
            <p>{text.Text}</p>
            <p className="text-lg-right pnc-learn-more">
              <a
                className="pnc-get-rewarded-cta"
                href={DOMPurify.sanitize(text.CTALink)}
                aria-label={`${text.CTAText} - Learn more about rewards`}
                target="_blank"
              >
                {text.CTAText}
              </a>
            </p>
          </div>
        </div>
        <div className="cards-container" role="list">
          {cardData.map(({ ImagePath, AltText, Rewards, Text }, index) => (
            <div className="card-wrapper" key={index} role="listitem">
              <Card
                className="flex-fill"
                tabIndex="0"
                aria-label={`Reward card for ${Rewards}`}
              >
                <Card.Img variant="top" src={DOMPurify.sanitize(ImagePath)} alt={AltText} />
                <Card.Body>
                  <Card.Text
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(Text),
                    }}
                  />
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default GetRewarded;
