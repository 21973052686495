import React, { useEffect, useState } from 'react';
import {  delete_cookie } from '../../read_cookie';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import config from "./../../configs/config";
import { read_cookie,bake_cookie } from "../../read_cookie";
const CLickBack = (props) => {
    const [token, setToken] = useState("token");
    let navigate = useNavigate();
    useEffect(() => {
        // /clickback/confirmation/
        const urlParams = new URLSearchParams(window.location.search);
        const GUID = urlParams.get("GUID");

        // user loggedin
        if (read_cookie("pnc-rewards-token")) {
            if (read_cookie("profileId") === GUID) {
                setToken(read_cookie("pnc-rewards-token"));
            } else {
                delete_cookie("pnc-rewards-token");
                delete_cookie("profileId");
            }
        }
        setEmailConfirm(GUID);
    }, [])

    const setEmailConfirm = (GUID) => {
        let header = { programcode: "RTL_PNT", token: token };
        const URL = config.qa.node_url;
        let apiUrl = 'emailConfirmation';
        let requestData = {
            CustomDataName: "EMAIL_VERIFIED",
            CustomDataValue: "Y",
            ProfileId: GUID
        }
        axios
            .post(URL + apiUrl, requestData, {
                headers: header,
            })
            .then((response) => {
                if (response && response.data) {
                    const res = response.data;
                    if (res.hasOwnProperty('ErrorCode')) {
                        bake_cookie("emailconfirm", "0");
                        navigate('/confirmation');
                    } else {
                        let { JsonExternalData } = res;
                        bake_cookie("emailconfirm", JsonExternalData.tknEmailConfirm);
                        navigate('/confirmation');
                    }
                }
            })
            .catch((error) => {
                bake_cookie("emailconfirm", "0");
                navigate('/confirmation');
            });
    }

    return (
        <section className="pnc-help-section">
            <div className="loader"></div>
        </section>
    );
}

export default CLickBack;