export function read_cookie(name) {
  if (typeof document === "undefined") return null;
  var cookie = document.cookie;
  var setPos = cookie.search(new RegExp("\\b" + name + "="));
  var stopPos = cookie.indexOf(";", setPos);
  var res;
  if (!~setPos) return null;
  res = decodeURIComponent(cookie.substring(setPos, ~stopPos ? stopPos : undefined).split("=")[1]);
  return res.charAt(0) === "{" ? JSON.parse(res) : res;
}

export function bake_cookie(name, value) {
  var cookie = [name, "=", value, "; domain_.", window.location.host.toString(), "; path=/;"].join(
    ""
  );
  document.cookie = cookie;
}

export function delete_cookie(name) {
  document.cookie = [
    name,
    "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/; domain.",
    window.location.host.toString(),
  ].join("");
}
