import React,{useEffect,useState} from 'react';
import { read_cookie } from "../../read_cookie";

const Confirmation = (props) => {
    document.title = props.title;
    const [emailConfirmation, setemailConfirmation] = useState("0");
    
    useEffect(() => {
        let emailConfirmStatus = read_cookie("emailconfirm");
        if (emailConfirmStatus === "1" || emailConfirmStatus === "2") {
            setemailConfirmation(emailConfirmStatus);
        }
    }, [])

    return(
        <section className="pnc-contact-us">
            <div className="container">
                <div className="row">
                    <div className="offset-md-1 col-md-11">
                        <div className="contact_us">
                            {
                                  emailConfirmation === "0" ?
                                  <div className="alert alert-danger" role="alert">
                                  Email address could not be verified.
                                  </div>
                                  : false
                            }
                            {
                                  emailConfirmation  === "1" ?
                                  <div className="alert alert-danger" role="alert">
                                   This email address has already been confirmed.
                                  </div>
                                  : false
                            }
                       
                            <h3 aria-level="1">EMAIL ADDRESS CONFIRMATION</h3>
                            {
                                emailConfirmation  === "1" || emailConfirmation === "0" ?
                                <h5>Your email address has already been confirmed.</h5>
                                :  emailConfirmation  === "2" ?
                                <>
                                    <h5>Your email address has been confirmed.</h5>
                                    <p>You may change this contact method at any time by logging in and updating your profile by visiting Update Profile.<br />
                                    If you need furthur information, Please visit the Frequently Asked Questions (FAQs) for more information (Communications under FAQs).</p>
                                </>
                                : false
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Confirmation;