import React, { useState, useEffect } from "react";
import { Toast } from "react-bootstrap";
import "./snackBar.css";
const ReactSnackBar = ({ Show, onClose, children }) => {
  const [showToast, setShowToast] = useState(Show);

  useEffect(() => {
    setShowToast(Show);

    // Automatically hide the toast after 3 seconds
    if (Show) {
      const timer = setTimeout(() => {
        setShowToast(false);
        if (onClose) {
          onClose(); // Trigger onClose callback
        }
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [Show, onClose]);

  return (
    <Toast show={showToast} delay={3000} autohide>
      <Toast.Body>{children}</Toast.Body>
    </Toast>
  );
};

export default ReactSnackBar;
