import React from "react";

import { Button, Card } from "react-bootstrap";
import { useStoreState } from "easy-peasy";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { toSentenceCase } from "../../utils/stringUtils"; 

import "./rewardsSlider.css";

function ArrowDisabled({ className, style, onClick }) {
  return <div className={className} style={{ ...style, display: "none" }} onClick={onClick} />;
}

const RewardsSlider = ({ redeemRewards }) => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <ArrowDisabled />,
    prevArrow: <ArrowDisabled />,
  };
  const getUserValue = useStoreState((state) => state.user.userInfo);

  return (
    <div className="pnc-rewards-slider">
      <div className="pnc-rewards-slider-container">
        <Slider {...settings}>
          {redeemRewards
            ? redeemRewards.map((redeemReward, index) => {
                return (
                  <Card key={index} className="pnc-rewards-slider-card" tabIndex="0" aria-label={`Redeem rewards card for ${redeemReward.title}`}>
                    <Card.Body>
                      <h3 id="pnc-redeem-rewards-section-heading">{redeemReward.headline}</h3>
                      <Card.Title tabIndex="0" role="main">
                        {redeemReward.title}
                      </Card.Title>
                      <div className="pnc-rewards-slider-desc" tabIndex="0">
                        {redeemReward.eventKey === "travel" ? (
                          getUserValue.JsonExternalData.CurrentBalance > 10000 ? (
                            <Card.Text>
                            {`${(toSentenceCase(getUserValue.FirstName)).trim()}, get up to $${(Math.round(getUserValue.JsonExternalData.CurrentBalance)*0.002).toFixed(2)} in travel rewards by redeeming ${
                              getUserValue.JsonExternalData.CurrentBalance ? Math.round(getUserValue.JsonExternalData.CurrentBalance).toLocaleString() : ""
                            } rewards points`}
                          </Card.Text>
                          ) : (
                            <Card.Text>
                            {`${(toSentenceCase(getUserValue.FirstName).trim())}, see how you can unlock travel rewards by earning rewards points`}
                          </Card.Text>
                          )
                        ) : (
                          <Card.Text>{redeemReward.desc}</Card.Text>
                        )}
                      </div>
                    </Card.Body>
                    <Card.Img variant="top" src={redeemReward.src} alt={redeemReward.alt} index="0" aria-label={redeemReward.alt} />
                    <Card.Body>
                      <Button className="pnc-rewards-slider-btn" href={global.catalogUrl+redeemReward.btnUrl} aria-label={`${redeemReward.btnText}`}>
                        {redeemReward.btnText}
                      </Button>
                      {redeemReward.btnText2 ? (
                        <Button className="pnc-rewards-slider-btn-2" href={global.catalogUrl+redeemReward.btnUrl2} aria-label={`${redeemReward.btnText2}`}>
                          {redeemReward.btnText2}
                        </Button>
                      ) : (
                        ""
                      )}
                    </Card.Body>
                  </Card>
                );
              })
            : false}
        </Slider>
      </div>
    </div>
  );
};

export default RewardsSlider;
