import React, { useEffect, useState } from "react";
import axios from "axios";
import Moment from "react-moment";
import { useStoreState, useStoreActions } from "easy-peasy";
import { Link } from "react-router-dom";
import { read_cookie } from "../../read_cookie";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { useFormattedDate } from '../../hooks/useFormattedDate';

import config from "./../../configs/config";
// const FormateCSTDate = (d) => {
//   const dateStr=new Date(d).toLocaleString('en-CA',{year: 'numeric', month: '2-digit', day: '2-digit' })+"T"+new Date(d).toLocaleString('en-GB',{hour: '2-digit', minute: '2-digit',second:'2-digit'})+"Z";
//   const CSTDate=new Date(dateStr).toLocaleString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit',timeZone: 'America/Chicago'})
//   return CSTDate
// }
const FormateDate = (d) => {
  const date = new Date(d)
  const formatedDate=date.toLocaleString('en-US',{year: 'numeric', month: '2-digit', day: '2-digit'  });
  return formatedDate;
}
export const TransactionLayout = ({ boxData }) => {
  let TransactionDescription = boxData.TransactionDescription.replace(
    /PNT|TVL|ONX/gi,
    ""
  );
  return (
    <div className="d-flex flex-wrap pnc-table-color mAlernate pr-2">
      <span className="p-3 col-8 col-md-9 col-lg-2 mdateAlignment">
        <Moment format="MM/DD/YYYY">{boxData.TransactionDateTime}</Moment>
        {/* {FormateCSTDate(boxData.TransactionDateTime)} */}
      </span>
      <span className="p-3 col-md-2 col-lg-2">{boxData.CardNumber}</span>
      <span className="p-3 col-12 col-md-12 col-lg-4">
        {TransactionDescription}
      </span>
      <span className="p-3 col-md-2 col-lg-2 text-lg-center">
        {parseFloat(boxData.TransactionNetTotal).toFixed(2)}
      </span>
      <span className="p-3 col-4 col-md-3 col-lg-2 text-lg-right bold-text">
        {boxData.PointsAmount}
      </span>
    </div>
  );
};

class accountPoints {
  constructor(earned, redeem, pending) {
    this.earned = earned;
    this.redeem = redeem;
    this.pending = pending;
  }
}

const onClickTooltip = (e) => { 
  e.preventDefault() 
}

const MyAccount = (props) => {
  document.title = props.title;
  const getUserValue = useStoreState((state) => state.user.userInfo);
  const getTransactions = useStoreActions(
    (actions) => actions.transcations.getTranscations
  );
  const getTransactionValue = useStoreState(
    (state) => state.transcations.transcationsInfo
  );
  let [orders, setOrders] = useState([]);
  let [myPoints, setmyPoints] = useState(new accountPoints(0, 0, 0));
  let [loading, setloading] = useState(false);
  let [loyaltyExpiring, setLoyaltyExpiring] = useState({
    NextLoyaltyExpiring: "0",
    NextLoyaltyExpiringDate: "2020-01-10T00:00:00",
  });

  useEffect(() => {
    setloading(true);
    getTransactions(sessionStorage.getItem("accessToken"));

    const URL = config.qa.node_url;
    let ProfileId = read_cookie("profileId");
    let apiUrl = `orderhistory`;
    let requestData = {
      ProfileId,
    };
    let header = { programcode: "RTL_PNT", token: sessionStorage.accessToken };
    axios
      .post(URL + apiUrl, requestData, {
        headers: header,
      })
      .then(function (response) {
        if (response.data) {
          setOrders(response.data);
        }
        setloading(false);
      })
      .catch(function (error) {
        console.error(error);
        // error;
        setloading(false);
      });
    // for getting points earned/ redeem
    header.script = "GetLifetimePointSummary";
    apiUrl = "invoke";
    axios
      .post(URL + apiUrl, requestData, {
        headers: header,
      })
      .then(function (response) {
        let accData = response.data;
        let earnedPoints =
          parseInt(accData.Base) +
          parseInt(accData.Bonus) +
          parseInt(accData.Adjustment);
        let redeemPoints = parseInt(accData.Reward) + parseInt(accData.Expired);
        setmyPoints(new accountPoints(earnedPoints, redeemPoints, 0));
        setloading(false);
      })
      .catch(function (error) {
        console.error(error);
        setloading(false);
        // error;
      });

    // for getting points Expiry Info
    header.script = "GetLinkPointInfo";
    apiUrl = "invoke";
    axios
      .post(URL + apiUrl, requestData, {
        headers: header,
      })
      .then(function (response) {
        setLoyaltyExpiring({
          NextLoyaltyExpiring: response.data.NextLoyaltyExpiring,
          NextLoyaltyExpiringDate: response.data.NextLoyaltyExpiringDate,
        });
        setloading(false);
      })
      .catch(function (error) {
        console.error(error);
        setloading(false);
        // error;
      });
  }, []);
  
  const pointsTooltip = (
    <Tooltip>
      This is the total number of points redeemed to date plus any points that have expired...
    </Tooltip>
  );

  const expirationTooltip = (
    <Tooltip>
      This is the total number of points in your PNC points account that will expire on the date shown...
    </Tooltip>
  );

  return (
    <section>
      <div className="container">
        {loading ? <div className="loader"></div> : false}
        <div className="wrap-acc">
          <div className="row">
            <div className="col-12 offset-md-2 col-md-8 offset-lg-0 col-lg-12">
              <h3 role="heading" aria-level="1">
                Rewards Overview
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-12 offset-md-2 col-md-8 offset-lg-0 col-lg-6">
              <p className="pnc-sub-heading">Account Summary</p>
              <div className="pnc-acc-summary-wrap">
                <p className="reward-point">
                  {getUserValue.FirstName
                    ? parseInt(
                        getUserValue.JsonExternalData.CurrentBalance
                      ).toLocaleString()
                    : ""}{" "}
                  available points
                </p>
                <div className="pnc-ponits-available">
                  <div>
                    <span>Points earned to date</span>
                    <span className="pnc-redeem-points">
                      {myPoints.earned.toLocaleString()}
                    </span>
                  </div>
                  <div>
                    <span>
                      Points redeemed/expired to date{" "}
                      <OverlayTrigger placement="top" overlay={pointsTooltip}>
                        <img src="/images/tootip.svg" className="pnc-tooltip" alt="tooltip" />
                      </OverlayTrigger>
                    </span>
                    <span className="pnc-redeem-points">
                      {myPoints.redeem.toLocaleString()}
                    </span>
                  </div>
                  <div>
                    <span>
                      Points pending expiration on{" "}
                      {/* {loyaltyExpiring.NextLoyaltyExpiringDate} */}
                      {/* {FormateDate(loyaltyExpiring.NextLoyaltyExpiringDate)} */}
                      {useFormattedDate(loyaltyExpiring.NextLoyaltyExpiringDate)}
                      {/* <Moment format="MM/DD/YYYY">
                        {loyaltyExpiring.NextLoyaltyExpiringDate}
                      </Moment> */}
                      {" "}
                      <OverlayTrigger placement="top" overlay={expirationTooltip}>
                        <img src="/images/tootip.svg" className="pnc-tooltip" alt="tooltip" />
                      </OverlayTrigger>
                    </span>
                    <span className="pnc-redeem-points">
                      {parseInt(
                        loyaltyExpiring.NextLoyaltyExpiring
                      ).toLocaleString()}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 offset-md-2 col-md-8 offset-lg-0 col-lg-6 mt-10">
              <p className="pnc-sub-heading">
                Recent Reward Orders
                <span className="pnc-see-summary">
                  {" "}
                  <Link to="/RewardsDetails/OrderHistory">
                    <button className="btn">See all orders</button>
                  </Link>
                </span>
              </p>
              <div className="pnc-flex-wrapper reward-offer-flex-container">
                {orders && orders.length > 0
                  ? orders.slice(0, 5).map((order, index) => {
                    let orderDescription = order.ItemDescription.replace(
                      /PNT|TVL|ONX/gi,
                      ""
                    );
                      // let orderDescription = order.ItemDescription;
                      // let lastIndex = orderDescription.lastIndexOf(" ");
                      // let orderItemDescription = orderDescription.substring(
                      //   0,
                      //   lastIndex
                      // );
                      return (
                        <div key={index} className="d-flex flex-wrap mwrapper">
                          <span className="p-3 col-8 col-md-9 col-lg-4 mdateAlignment">
                          {FormateDate(order.OrderDate)}
                            {/* <Moment format="MM/DD/YYYY"  tz='Asia/Kolkata'>
                             
                              {order.OrderDate}
                            </Moment> */}
                          </span>
                          <span className="p-3 col-12 col-md-12 col-lg-5 mdescriptionAlignment">
                            {orderDescription}
                          </span>
                          <span className="p-3 col-4 col-md-3 col-lg-3 mStatusAlignment">
                            {order.OrderStatus}
                          </span>
                        </div>
                      );
                    })
                  : false}
              </div>
              <Link to="/RewardsDetails/OrderHistory">
                <button className="btn see-order">See all orders</button>
              </Link>
            </div>
          </div>
          <div className="row pnc-divider">
            <div className="col-12 offset-md-2 col-md-8 offset-lg-0 col-lg-12">
              <p className="pnc-sub-heading">
                Recent Transactions
                <span className="pnc-see-orders">
                  {" "}
                  <Link to="/RewardsDetails/Transactions">
                    <button className="btn">See all Transactions</button>
                  </Link>
                </span>
              </p>
              <div className="pnc-flex-wrapper-order">
                <div className="d-flex pnc-table-color-order pr-2">
                  <div className="p-3 col-lg-2">Date</div>
                  <div className="p-3 col-lg-2">Card #</div>
                  <div className="p-3 col-lg-4">Description</div>
                  <div className="p-3 col-lg-2 text-lg-center">Amount (USD)</div>
                  <div className="p-3 col-lg-2 text-lg-right">Points</div>
                </div>
                {Array.isArray(getTransactionValue) ? (
                  getTransactionValue.slice(0, 5).map((value, index) => {
                    return (
                      <TransactionLayout
                        boxData={value}
                        key={index}
                      ></TransactionLayout>
                    );
                  })
                ) : (
                  <div className="d-flex flex-wrap pnc-table-color mAlernate pr-2">
                    <span className="p-3 col-8 col-md-9 col-lg-4 mdateAlignment">
                      No Data
                    </span>
                  </div>
                )}
              </div>
              <Link to="/RewardsDetails/Transactions">
                <button className="btn see-transaction">
                  See all Transactions
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MyAccount;
