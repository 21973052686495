import React, { useState } from 'react';
import './disclosure.css'; // Archivo CSS opcional para estilos

const Disclosure = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleDisclosure = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='pnc-cmp-accordion' role='region' aria-labelledby='accordion-1' data-cmp-is='accordion'>
      <div className='pnc-disclosure-title'
          aria-expanded={isOpen}
          aria-controls='accordion-content-1'
          onClick={toggleDisclosure}>
        <h4 className='pnc-disc-title' id='accordion-1'>Important Legal Disclosures & Information </h4>
        <span
          className='pnc-accordion-button'
        >
          {isOpen ? '-' : '+'}
        </span>
      </div>

      {isOpen && (
        <article
          id='pnc-disclosure-cnt'
          className='pnc-disclosure-content'
          role='article'
        >
          <div className='pnc-disclosure-content-styles'>
            <div className='pnc-first-disclosure'>
              <span className='pnc-disc-number-one'>1. </span>
              <p className='pnc-disc-first-p'>PNC does not charge a fee for Mobile Banking. However, third party message and data rates may apply. These include fees your wireless carrier may charge you for data usage and text messaging services. Check with your wireless carrier for details regarding your specific wireless plan and any data usage or text messaging charges that may apply. Also, a supported mobile device is needed to use the Mobile Banking App. Mobile Deposit is a feature of PNC Mobile Banking. Use of the Mobile Deposit feature requires a supported camera-equipped device and you must download a PNC mobile banking app. Eligible PNC Bank account and PNC Bank Online Banking required. Certain other restrictions apply. See the mobile banking terms and conditions in the <a title="PNC Online Banking Service Agreement" href="https://www.pnc.com/content/dam/pnc-com/pdf/personal/OnlineBanking/olb-service-agreement.pdf">PNC Online Banking Service Agreement</a></p>
            </div>
            <p>The customer videos on this site are non-paid testimonials by real PNC customers.</p>
            <img className='pnc-disc-img' src="/images/logo-equal-housing-lender-white.svg"/>
            <div className='pnc-disc-texts'>
              <p>PNC, PNC HomeHQ, PNC Home Insight, and Home Insight are registered service marks of The PNC Financial Services Group, Inc. ("PNC"). PNC has pending patent applications directed at various features and functions of Home Insight Planner and Home Insight Tracker. All loans are provided by PNC Bank, National Association, a subsidiary of PNC, and are subject to credit approval and property appraisal.</p>
            </div>
            <div>
              <p>The PNC Financial Services Group, Inc. ("PNC") uses the marketing names PNC Private Bank℠ and PNC Private Bank Hawthorn® to provide investment consulting and wealth management, fiduciary services, FDIC-insured banking products and services, and lending of funds to individual clients through PNC Bank, National Association ("PNC Bank"), which is a <b>Member FDIC</b>, and to provide specific fiduciary and agency services through its subsidiary, PNC Delaware Trust Company or PNC Ohio Trust Company. PNC uses the marketing name PNC Institutional Asset Management® for the various discretionary and non-discretionary institutional investment, trustee, custody and related services provided by PNC Bank, and investment management activities conducted by PNC Capital Advisors, LLC, an SEC-registered investment adviser and wholly-owned subsidiary of PNC Bank (“PNC Capital Advisors”). PNC uses the marketing name PNC Institutional Advisory Solutions®&nbsp;for discretionary investment management, trustee, and related services conducted by PNC Bank. PNC uses the marketing name PNC Retirement Solutions®&nbsp;for investment, consulting, trustee, and custody services for employer-sponsored retirement plans provided by PNC Bank. PNC does not provide legal, tax, or accounting advice unless, with respect to tax advice, PNC Bank has entered into a written tax services agreement. PNC Bank is not registered as a municipal advisor under the Dodd-Frank Wall Street Reform and Consumer Protection Act ("Act"). Investment management and related products and services provided to a "municipal entity" or "obligated person" regarding "proceeds of municipal securities" (as such terms are defined in the Act) will be provided by PNC Capital Advisors.</p>
            </div>
            <div className='pnc-disc-texts'>
              <b>Investments: Not FDIC Insured. No Bank Guarantee. May Lose Value.</b>
            </div>
            <div className='pnc-disc-texts'>
              <p>©2024 The PNC Financial Services Group, Inc. All rights reserved. PNC Bank, National Association. <b>Member FDIC.</b></p>
            </div>
            <div className='pnc-disc-texts'>
              <p><a href="https://www.pnc.com/en/privacy-policy.html#privacypolicy5" title="Read a summary of privacy rights for California residents">Read a summary of privacy rights for California residents</a>&nbsp;which outlines the types of information we collect, and how and why we use that information.</p>
            </div>
          </div>
        </article>
      )}
    </div>
  );
};

export default Disclosure;