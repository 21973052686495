import React from 'react';
import { useStoreState } from "easy-peasy";
import { useFormattedDate } from '../../hooks/useFormattedDate';

import './dashboard.css';

const RewardsDashboard = ({ dashboardData, pointsEarned }) => {
  
  const { rewardsBalanceText, pointsEarnedText, mainTitle, mainSubtitle } = dashboardData[0];

  const getUserValue = useStoreState((state) => state.user.userInfo);

  const visibleCards = [
    getUserValue.JsonExternalData.CurrentBalance && { title: rewardsBalanceText.title, type: 'rewardsBalance' },
    getUserValue.JsonExternalData.CurrentAnniversaryPoints && { title: pointsEarnedText.title, type: 'pointsEarned' }
  ].filter(Boolean);

  const formattedDateWithOneYearSubtracted = useFormattedDate(getUserValue?.JsonExternalData?.AnniversaryDate, 1);

  return (
    <section className="pnc-rewards-dashboard" role="main">
      <header className="pnc-rewards-header" aria-label="Reward Dashboard Header">
        <div className="pnc-card-info">
          <h2>{mainTitle}</h2>
          <div className='d-flex pnc-dashboard-content'>
            <img src="/images/pnc-points-card.png" alt="PNC Points Card" className="pnc-card-image" />
            <h5>{mainSubtitle}</h5>
          </div>
        </div>
      </header>

      <div className={`pnc-rewards-flex ${visibleCards.length === 1 ? 'pnc-one-single-card' : visibleCards.length === 3 ? 'pnc-three-cards' : ''}`}>
        {/* Rewards Balance */}
        { getUserValue.JsonExternalData.CurrentBalance && (
          <article className="pnc-reward-box pnc-points-earned-box" role="article" aria-labelledby="rewardsBalance">
          <div className="pnc-reward-info">
            <p className='pnc-rewards-dashboard-titles mb-0'>{rewardsBalanceText.title}</p>
            <div className='d-flex pnc-rewards-balance-text pnc-justify-center align-items-end'>
              <h2 className='pnc-orange-font mb-0'>{Math.round(getUserValue.JsonExternalData.CurrentBalance).toLocaleString()}</h2>
              <h6 className='pnc-points-number'>{rewardsBalanceText.points}</h6>
            </div>
            <div className='pnc-grey-reward'>
              <button aria-label='view rewards' 
                alt={rewardsBalanceText.btnAlt} 
                onClick={() => window.location.href = rewardsBalanceText.btnURL}
              >{rewardsBalanceText.btnText}</button>
            </div>
          </div>
        </article>
        )}
        
        {/* Points Earned*/}
        {getUserValue.JsonExternalData.CurrentAnniversaryPoints && (
          <article className="pnc-reward-box pnc-points-earned-box" role="article" aria-labelledby="pointsEarned">
            <div className="pnc-reward-info">
              <p className='pnc-rewards-dashboard-titles mb-0' id="pointsEarned">{pointsEarnedText.title}</p>
              <div className='d-flex pnc-rewards-balance-text pnc-justify-center align-items-end'>
                <h2 className='pnc-orange-font mb-0'>{new Intl.NumberFormat().format(getUserValue.JsonExternalData.CurrentAnniversaryPoints)}</h2>
                <h6 className='pnc-points-number'>{pointsEarnedText.points}</h6>
              </div>
              <div className='pnc-grey-reward'>
                <p className='mb-0'>{pointsEarnedText.anniversary}</p>
                <span className='mb-0 pnc-orange-font'>{formattedDateWithOneYearSubtracted}</span>
              </div>
            </div>
          </article>
        )}

        {/* Travel Credit this code will not be used rn, can be used in the future*/}
        {/* {travelCredit && (
          <article className="pnc-reward-box" role="article" aria-labelledby="travelCredit">
            <div className="pnc-reward-info">
              <p className='pnc-rewards-dashboard-titles' id="travelCredit">{travelCreditText.title}</p>
              <h2 className='pnc-orange-font mb-0'>${travelCredit}</h2>
              <h6 className='mb-0'>{travelCreditText.earned}</h6>
                <p className='mb-0'>{travelCreditText.asOf} <Moment format="MM/DD/YYYY">{getUserValue.JoinDate}</Moment></p>
            </div>
          </article>
        )} */}

        {/* Digital Subscription this code will not be used rn, can be used in the future*/}
        {/* {digitalSubscription && (
          <article className="pnc-reward-box" role="article" aria-labelledby="digitalSubscription">
            <div className="pnc-reward-info">
              <p className='pnc-rewards-dashboard-titles' id="digitalSubscription">{digitalSuscriptionText.title}</p>
              <h2 className='pnc-orange-font mb-0'>${digitalSubscription}</h2>
              <h6>{digitalSuscriptionText.earned}</h6>
            </div>
          </article>
        )} */}
      </div>
    </section>
  );
};

export default RewardsDashboard;