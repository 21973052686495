import React from "react";
import { StoreProvider } from "easy-peasy";
import Store from "./service/store";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Home from "./components/home/home";
import Contact from "./components/contact/contact";
import MyProfile from "./components/myProfile/myProfile";
import MyCommunication from "./components/myCommunication/myCommunication";
import MyAccount from "./components/myAccount/myAccount";
import MessageDetails from "./components/messageDetails/messageDetails";
import Transaction from "./components/transaction/transaction";
import OrderHistory from "./components/orderHistory/orderHistory";
import RewardMessages from "./components/rewardMessage/rewardMessages";
import LinkedCards from "./components/linkedCards/linkedCards";
import Resp from "./components/Redirect";
import SAML from "./components/bakktSaml";
import Logout from "./components/logout/logout";
import Faq from "./components/faq/faq";
import FrontPorch from "./components/frontporch";
import Confirmation from "./components/confirmation/confirmation";
import Notfound from "./components/notfound";
import Auth from "./components/auth";
import CLickBack from "./components/confirmation/clickback";
import ErrorPage from "./components/errorPage";
import MaintenancePage from "./components/maintenancePage";
import Benefits from "./components/benefits/benefits";

const PrivateRoute = ({ children }) => {
  return Auth.getAuth() ? children : <Navigate to="/" />;
};

Auth.authenticate();

function App() {
  let catalogUrl;
  if (process.env.REACT_APP_ENV === "production") {
    catalogUrl = process.env.REACT_APP_CATALOG_URL;
  } else {
    catalogUrl = process.env.REACT_APP_CATALOG_URL_QA;
  }
  global.catalogUrl = catalogUrl;

  return (
    <React.Fragment>
      <StoreProvider store={Store}>
        <Router>
          <Header />
          <section className="pnc-wrapper">
            <Routes>
              {process.env.REACT_APP_MAINTANENCE === "true" ? (
                <Route
                  path="*"
                  element={
                    <MaintenancePage title="PNC Points | MaintenancePage" />
                  }
                />
              ) : (
                <>
                  <Route
                    path="/home"
                    element={<Home title="PNC Points | Home" />}
                  />
                  <Route
                    path="/"
                    element={<Home title="PNC Points | Home" />}
                  />
                  <Route
                    path="/contact"
                    element={<Contact title="PNC Points | Contact Us" />}
                  />
                  <Route
                    path="/Help/ContactUs"
                    element={<Contact title="PNC Points | Contact Us" />}
                  />
                  <Route
                    path="/MyProfile/Communications"
                    element={
                      <PrivateRoute>
                        <MyCommunication title="PNC Points | My Communications" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/RewardsDetails/RewardsOverview"
                    element={
                      <PrivateRoute>
                        <MyAccount title="PNC Points | Rewards Overview" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/MyProfile/Messaging/messageDetails/:type/:id"
                    element={
                      <PrivateRoute>
                        <MessageDetails title="PNC Points | Message Details" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/RewardsDetails/Transactions"
                    element={
                      <PrivateRoute>
                        <Transaction title="PNC Points | Transactions" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/RewardsDetails/OrderHistory"
                    element={
                      <PrivateRoute>
                        <OrderHistory title="PNC Points | Order History" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/RewardsDetails/Benefits"
                    element={
                      <PrivateRoute>
                        <Benefits title="PNC Points | Benefits" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/MyProfile/Profile"
                    element={
                      <PrivateRoute>
                        <MyProfile title="PNC Points | My Profile" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/MyProfile/Messaging"
                    element={
                      <PrivateRoute>
                        <RewardMessages title="PNC Points | Messages" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/MyProfile/LinkedCards"
                    element={
                      <PrivateRoute>
                        <LinkedCards title="PNC Points | Linked Cards" />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/Help/FAQs"
                    element={<Faq title="PNC Points | FAQ" />}
                  />
                  <Route path="/SAML/b2rLogin" element={<SAML />} />
                  <Route path="/FrontPorch" element={<FrontPorch />} />
                  <Route
                    path="/confirmation"
                    element={
                      <Confirmation title="PNC Points | Email Address Confirmation" />
                    }
                  />
                  <Route
                    path="/clickback/confirmation/"
                    element={
                      <CLickBack title="PNC Points | Email Address Confirmation" />
                    }
                  />
                  <Route path="/resp/:id" element={<Resp />} />
                  <Route path="/Logout/Logout" element={<Logout />} />
                  <Route
                    path="/errorPage"
                    element={<ErrorPage title="PNC Points | Error" />}
                  />
                  <Route
                    path="*"
                    element={<Notfound title="PNC Points | System Error" />}
                  />
                </>
              )}
            </Routes>
          </section>
          <Footer />
        </Router>
      </StoreProvider>
    </React.Fragment>
  );
}

export default App;
