import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const FrontPorch = () =>{
   const navigate = useNavigate();
 useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const returnUrl = params.get('ReturnUrl');
    navigate(returnUrl);
 },[])

    return (
        <section className="pnc-help-section">
              <div className="loader"></div>
          <div className="container">Loading.....
          </div>
        </section>
      );
}

export default FrontPorch;