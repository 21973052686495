import React from "react";
import { useState, useEffect } from "react";

import RewardsSlider from "../rewardsSlider/rewardsSlider";
import RewardsTabs from "../rewardsTabs/rewardsTabs";

import redeemRewardsData from "./redeemRewards.json";
import "./redeemRewards.css";

const RedeemRewards = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return <section className="pnc-redeem-rewards" aria-labelledby="pnc-redeem-rewards-section-heading">{width > 768 ? <RewardsTabs redeemRewards={redeemRewardsData.rewards} /> : <RewardsSlider redeemRewards={redeemRewardsData.rewards} />}</section>;
};

export default RedeemRewards;
