import React from 'react';
import './travelProtection.css';
import data from "./../../data/travelProtection.json";
import { Container, Row, Col, Button } from 'react-bootstrap';
import DOMPurify from "dompurify";

const TravelProtection = () => {

  const { travelProtection } = data.protectionData[0]
  const { everydayProtection } = data.protectionData[1]

  return (
    <Container fluid className="protection-section" role="region" aria-label="Protection Section">
      <Row className="pnc-travel-protection-wrap pnc-desk-px-2 ">
        <Col md={8} className='pnc-protection-custom-column'>
        <div className='pnc-protection-list-content'>
        <Col md={6} className="text-left pnc-protection-list-container" role="region" aria-labelledby="travelProtectionTitle">
            <div className='pnc-protection-text-container'>
              <div id="travelProtectionTitle" className="pnc-protection-title">{travelProtection.title}</div>
              <p className="protection-description">
                {travelProtection.subtitle}
              </p>
            </div>
            <ul className="pnc-protection-list" aria-label="Travel Protection Services">
              {travelProtection.services.map((element, index) => (
                <li key={index} tabIndex="0">{element}</li>
              ))}
            </ul>
          </Col>
          <Col md={6} className="text-left pnc-protection-list-container" role="region" aria-labelledby="everydayProtectionTitle">
            <div className='pnc-protection-text-container'>
              <div id="everydayProtectionTitle" className="pnc-protection-title">{everydayProtection.title}</div>
              <p className="protection-description">
                {everydayProtection.subtitle}
              </p>
            </div>
            <ul className="pnc-protection-list" aria-label="Everyday Protection Services">
              {everydayProtection.services.map((element, index) => (
                  <li key={index} tabIndex="0">{element}</li>
                ))}
            </ul>
          </Col>
        </div>
        <div className='pnc-protection-button-container'>
          <Button className="pnc-protection-learn-more-btn btn" href={DOMPurify.sanitize(data.CTALink)} aria-label="Learn more about protection services" target="_blank">Learn More</Button>
        </div>
        </Col>
        <Col md={4} className="text-center pnc-protection-image p-0" role="img" aria-label="Protection Shield Image">
          <img src='/images/placeholders/benefits_04.jpg' alt="Protection Shield" className="protection-image" />
        </Col>
      </Row>
    </Container>
  );
};

export default TravelProtection;
