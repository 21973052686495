import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import Pagination from "react-js-pagination";
import { useStoreState, useStoreActions, Action } from "easy-peasy";

// const FormateCSTDate = (d) => {
// const dateStr=new Date(d).toLocaleString('en-CA',{year: 'numeric', month: '2-digit', day: '2-digit' })+"T"+new Date(d).toLocaleString('en-GB',{hour: '2-digit', minute: '2-digit',second:'2-digit'})+"Z";
// const CSTDate=new Date(dateStr).toLocaleString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit',timeZone: 'America/Chicago'})
// const CSTTime=new Date(dateStr).toLocaleString('en-US', {hour: '2-digit', minute: '2-digit',timeZone: 'America/Chicago'})
// return CSTDate+" "+CSTTime
// }
export const TableTd = ({ boxData }) => {
  let TransactionDescription = boxData.TransactionDescription.replace(
    /PNT|TVL|ONX/gi,
    ""
  );
  return (
    <div className="d-flex flex-wrap mAlernate pr-2">
      <span className="p-3 col-8 col-md-9 col-lg-2 mdateAlignment">
        <span className="small">
          {/* {FormateCSTDate(boxData.TransactionDateTime)} */}
          <Moment format="MM/DD/YYYY HH:mm A">
            {boxData.TransactionDateTime}
          </Moment>
        </span>
      </span>
      <span className="p-3 col-md-2 col-lg-2 text-lg-center">
        {boxData.CardNumber}
      </span>
      <span className="p-3 col-12 col-md-12 col-lg-4">
        {TransactionDescription}
      </span>
      <span className="p-3 col-md-2 col-lg-2 text-right">
        {boxData.TransactionNetTotal ? parseFloat(boxData.TransactionNetTotal).toFixed(2) : "" }
      </span>
      <span className="p-3 col-4 col-md-3 col-lg-2 text-lg-right">
        {boxData.PointsAmount}
        <span className="points-mobile-only">pts</span>
      </span>
    </div>
  );
};
export const inclusiveRange = (start, end, step) => {
  return Array.from(
    Array.from(Array(Math.ceil(end / step)).keys()),
    (x) => start + x * step
  );
};

const Transaction = (props) => {
  document.title = props.title;

  const getTransactions = useStoreActions(
    (actions) => actions.transcations.getTranscations
  );
  const getTransactionValue = useStoreState(
    (state) => state.transcations.transcationsInfo
  );
  let [recordsArr, setRecordsArr] = useState([10]);
  let [records, setRecords] = useState(10);
  let [count, setCount] = useState(0);
  let [cardArr, setCardArr] = useState([]);
  let [trans, setTrans] = useState([]);
  let [currStatus, setcurrStatus] = useState("All");
  let [currFrom, setcurrFrom] = useState(7);
  let [mon, setMon] = useState([]);
  let [activePage, setactivePage] = useState(1);
  let [startCount, setStartCount] = useState(0);
  let [sort, setsort] = useState("down");

  const mlist = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const updateRecords = (e) => {
    setRecords(parseInt(e.target.value));
    handlePageChange(1);
  };

  useEffect(() => {
    getTransactions(sessionStorage.getItem("accessToken"));
    let currMonth = new Date().getMonth();
    let currYear = new Date().getFullYear();
    //  let currMonth = 0;
    switch (currMonth) {
      case 0:
        setMon([`${mlist[currMonth]}/${currYear}`, `${mlist[11]}/${currYear-1}`, `${mlist[10]}/${currYear-1}`]);
        break;
      case 1:
        setMon([`${mlist[currMonth]}/${currYear}`, `${mlist[0]}/${currYear}`, `${mlist[11]}/${currYear-1}`]);
        break;
      default:
        setMon([
          `${mlist[currMonth]}/${currYear}`,
          `${mlist[currMonth - 1]}/${currYear}`,
          `${ mlist[currMonth - 2]}/${currYear}`,
        ]);
    }
  }, []);
  var month_name = function (dt) {
    return mlist[dt.getMonth()];
  };
  if (getTransactionValue) {
    if (getTransactionValue.length > 0 && count === 0) {
      setCount(1);
      let numberOfRecords = getTransactionValue.length > 0 ? getTransactionValue.length : 1;
      let today = new Date();
      let lastWeek = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - currFrom
      );
      let filterdOrders = getTransactionValue.filter((order) => {
        return new Date(order.TransactionDateTime) > lastWeek;
      });
      setTrans(filterdOrders);
     // setTrans(getTransactionValue.slice(0, 90));
      setRecordsArr(inclusiveRange(10, numberOfRecords, 10));
      const uniquecardobj=getTransactionValue.filter((item) => {
        var d = new Date();
        d.setMonth(d.getMonth() - 2);
        d.setDate(1);
       return new Date(item.TransactionDateTime)>d
        });
      const unique = [
        ...new Set(uniquecardobj.map((item) =>  item.CardNumber)),
      ];
      // console.log(unique,uniquecardobj,filterdOrders,"uniquecardobj")
      // const unique=[...new Set(getTransactionValue.map((item) =>  item.CardNumber))]
      // const uniqueMonth = [...new Set(getTransactionValue.map(item => {
      //     return month_name(new Date(item.TransactionDateTime));
      // }))];  
      console.log(getTransactionValue.length,getTransactionValue,"getTransactionValue") 
      setCardArr(unique);
    }
  }

  const filterbyCard = (e) => {
    let cardnum = e.target.value;
    setcurrStatus(cardnum);
    updateRec(cardnum);
  };

  const filterbyDate = (e) => {
    let currDate = e.target.value;
    setcurrFrom(currDate);
    updateRec(null, currDate);
  };

  const getDate = (days) => {
    let today = new Date();
    let lastWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - days
    );
    return lastWeek;
  };

  const handlePageChange = (pagenum) => {
    setactivePage(pagenum);
    let stCount = pagenum * records - records;
    // let endCount = (pagenum * records)+1;
    // console.log(stCount,records,trans.slice(stCount,(stCount+records)),(stCount+records))
    setStartCount(stCount);
  };

  const sortDataDate = (des) => {
    if (!des) {
      if (sort === "downDate") {
        let sortedval = trans.sort(
          (a, b) =>
            new Date(a.TransactionDateTime) - new Date(b.TransactionDateTime)
        );
        setsort("upDate");
      } else {
        let sortedval = trans.sort(
          (a, b) =>
            new Date(b.TransactionDateTime) - new Date(a.TransactionDateTime)
        );
        setsort("downDate");
      }
    } else {
      let sortedval = trans.sort(
        (a, b) =>
          new Date(b.TransactionDateTime) - new Date(a.TransactionDateTime)
      );
      setsort("downDate");
    }
  };

  const sortDataQty = (des) => {
    if (!des) {
      if (sort === "downQty") {
        let sortedval = trans.sort((a, b) => a.CardNumber - b.CardNumber);
        setsort("upQty");
      } else {
        let sortedval = trans.sort((a, b) => b.CardNumber - a.CardNumber);
        setsort("downQty");
      }
    } else {
      let sortedval = trans.sort((a, b) => a.CardNumber - b.CardNumber);
      setsort("upQty");
    }
  };

  const sortDataOrder = (des) => {
    if (!des) {
      if (sort === "downOrder") {
        let sortedval = trans.sort((a, b) =>
          a.TransactionDescription.localeCompare(b.TransactionDescription)
        );
        setsort("upOrder");
      } else {
        let sortedval = trans.sort((a, b) =>
          b.TransactionDescription.localeCompare(a.TransactionDescription)
        );
        setsort("downOrder");
      }
    } else {
      let sortedval = trans.sort((a, b) =>
        a.TransactionDescription.localeCompare(b.TransactionDescription)
      );
      setsort("upOrder");
    }
  };

  const sortDataTotal = (des) => {
    if (!des) {
      if (sort === "downTotal") {
        let sortedval = trans.sort(
          (a, b) => a.TransactionNetTotal - b.TransactionNetTotal
        );
        setsort("upTotal");
      } else {
        let sortedval = trans.sort(
          (a, b) => b.TransactionNetTotal - a.TransactionNetTotal
        );
        setsort("downTotal");
      }
    } else {
      let sortedval = trans.sort(
        (a, b) => a.TransactionNetTotal - b.TransactionNetTotal
      );
      setsort("upTotal");
    }
  };

  const sortDataPoints = (des) => {
    if (!des) {
      if (sort === "downPoints") {
        let sortedval = trans.sort((a, b) => a.PointsAmount - b.PointsAmount);
        setsort("upPoints");
      } else {
        let sortedval = trans.sort((a, b) => b.PointsAmount - a.PointsAmount);
        setsort("downPoints");
      }
    } else {
      let sortedval = trans.sort((a, b) => a.PointsAmount - b.PointsAmount);
      setsort("upPoints");
    }
  };

  const updateRec = (status, from) => {
    handlePageChange(1);
    status = status ? status : currStatus;
    from = from ? from : currFrom;
    if (status === "All" && from === "all") {
      setTrans(getTransactionValue);
    } else if (status !== "All" && from === "all") {
      let filterdOrders = getTransactionValue.filter((order) => {
        return order.CardNumber === status;
      });
      setTrans(filterdOrders);
    } else if (status !== "All" && from !== "all") {
      let fromInt = parseInt(from);
      if (fromInt) {
        let filterdOrders = getTransactionValue.filter((order) => {
          return (
            order.CardNumber === status &&
            new Date(order.TransactionDateTime) > getDate(fromInt)
          );
        });
        setTrans(filterdOrders);
      } else {
        let filterdOrders = getTransactionValue.filter((order) => {
          var orderDt = new Date(order.TransactionDateTime);
          var orderYear = orderDt.getFullYear();
          return (
            order.CardNumber === status &&
            `${month_name(new Date(order.TransactionDateTime))}/${orderYear}` === from
          );
        });
        setTrans(filterdOrders);
      }
    } else if (status === "All" && from !== "all") {
      let fromInt = parseInt(from);
      if (fromInt) {
        let filterdOrders = getTransactionValue.filter((order) => {
          return new Date(order.TransactionDateTime) > getDate(fromInt);
        });
        setTrans(filterdOrders);
      } else {
        let filterdOrders = getTransactionValue.filter((order) => {
          var orderDt = new Date(order.TransactionDateTime);
          var orderYear = orderDt.getFullYear();
          return `${month_name(new Date(order.TransactionDateTime))}/${orderYear}` === from;
        });
        setTrans(filterdOrders);
      }
    }
    sortDataDate("des");
  };

  return (
    <div className="container">
      <div className="wrap-transaction">
        <div className="row">
          <div className="col-12 offset-md-2 col-md-8 offset-lg-0 col-lg-12 heading-with-filter">
            <h3 role="heading" aria-level="1">
              Transactions
            </h3>
            <span className="sort float-right">
              <a className="sort-inactive" href="#">
                <img
                  className="sort-img"
                  src="/images/sort-filter.svg"
                  alt="sort-filter"
                />
                Sort/Filter
              </a>
              <a className="sort-expand" href="#">
                <img
                  className="close-img"
                  src="/images/close-button.svg"
                  alt="close"
                />{" "}
                Close
              </a>
            </span>
          </div>
          <div className="col-12 offset-md-2 col-md-8 offset-lg-0 col-lg-12 pagination-wrap">
            <div className="d-flex pnc-transaction-pagination float-right">
              <div className="month-filter">
                <span>From</span>
                <select
                  className="form-control selectBox"
                  aria-label="selectbox-filter"
                  onChange={filterbyDate}
                >
                  {/* <option value="all">All </option> */}
                  <option value="7">Last Week</option>
                  <option value="30">Last 30 Days</option>
                  {mon.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item.split('/')[0]}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="card-filter">
                <span>On Card</span>
                <select
                  id="filter"
                  className="form-control selectBox"
                  aria-label="selectbox-sort"
                  onChange={filterbyCard}
                >
                  <option value="All">All </option>

                  {cardArr.map((card, index) => {
                    return (
                      <option value={card} key={index}>
                        {card}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="float-right view-per-page-sec">
                <div className="d-flex view-per-page-sec-inner">
                  <span>View</span>

                  <select
                    id="filter"
                    className="form-control selectBox"
                    onChange={updateRecords}
                    role="listbox"
                    aria-label="selectbox-pagination"
                  >
                    {recordsArr.slice(0,10).map((rec, index) => {
                      return (
                        <option value={rec} key={index}>
                          {rec}
                        </option>
                      );
                    })}
                  </select>
                  <span>Per page</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 offset-md-2 col-md-8 offset-lg-0 col-lg-12">
            <div className="pnc-sort-mb">
              <p>FILTER BY</p>
              <label>From</label>
              <select
                className="form-control selectBox"
                aria-label="selectbox-filter"
                onChange={filterbyDate}
              >
                {/* <option value="all">All </option> */}
                <option value="7">Last Week</option>
                <option value="30">Last 30 Days</option>
                {mon.map((item, index) => {
                  return (
                    <option value={item} key={index}>
                      {item.split('/')[0]}
                    </option>
                  );
                })}
              </select>
              <label>On Card</label>
              <select
                id="filter-mb"
                className="form-control selectBox"
                aria-label="selectbox-sort"
                onChange={filterbyCard}
              >
                <option value="All">All </option>

                {cardArr.map((card, index) => {
                  return (
                    <option value={card} key={index}>
                      {card}
                    </option>
                  );
                })}
              </select>
              {/* <p>SORT BY</p>
              <select
                id="filter-mb"
                className="form-control selectBox"
                onChange={updateRecords}
                role="listbox"
                aria-label="selectbox-pagination"
              >
                {recordsArr.slice(0,10).map((rec, index) => {
                  return (
                    <option value={rec} key={index}>
                      {rec}
                    </option>
                  );
                })}
              </select> */}
              <button type="submit" className="btn apply-filter">
                Apply Filter
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 offset-md-2 col-md-8 offset-lg-0 col-lg-12">
            <div className="pnc-transaction-wrapper">
              <div className="d-flex pnc-transaction-table pr-2">
                <div
                  className="p-3 col-lg-2"
                  onClick={() => {
                    sortDataDate();
                  }}
                >
                  Date & Time{" "}
                  {sort === "upDate" ? (
                    <i className={"fa fa-sort-up"}></i>
                  ) : (
                    <i className={"fa fa-sort-down"}></i>
                  )}
                </div>
                <div
                  className="p-3 col-lg-2 text-lg-center"
                  onClick={() => {
                    sortDataQty();
                  }}
                >
                  Card #{" "}
                  {sort === "upQty" ? (
                    <i className={"fa fa-sort-up"}></i>
                  ) : (
                    <i className={"fa fa-sort-down"}></i>
                  )}
                </div>
                <div
                  className="p-3 col-lg-4"
                  onClick={() => {
                    sortDataOrder();
                  }}
                >
                  Description{" "}
                  {sort === "upOrder" ? (
                    <i className={"fa fa-sort-up"}></i>
                  ) : (
                    <i className={"fa fa-sort-down"}></i>
                  )}
                </div>
                <div
                  className="p-3 col-lg-2 text-right"
                  onClick={() => {
                    sortDataTotal();
                  }}
                >
                  Amount (USD){" "}
                  {sort === "upTotal" ? (
                    <i className={"fa fa-sort-up"}></i>
                  ) : (
                    <i className={"fa fa-sort-down"}></i>
                  )}
                </div>
                <div
                  className="p-3 col-lg-2 text-right"
                  onClick={() => {
                    sortDataPoints();
                  }}
                >
                  Points{" "}
                  {sort === "upPoints" ? (
                    <i className={"fa fa-sort-up"}></i>
                  ) : (
                    <i className={"fa fa-sort-down"}></i>
                  )}
                </div>
              </div>
              {trans.length > 0 ? (
                trans
                  .slice(startCount, startCount + records)
                  .map((value, index) => {
                    return <TableTd boxData={value} key={index}></TableTd>;
                  })
              ) : (
                <div className="d-flex flex-wrap mAlernate pr-2">
                  <div className="p-3 col-lg-12">
                    You have no transactions in your transaction history.
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="float-right view-per-page-sec">
          <div className="d-flex view-per-page-sec-inner">
            <Pagination
              activePage={activePage}
              itemsCountPerPage={records}
              totalItemsCount={trans.length}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              hideDisabled={true}
            />
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default Transaction;
