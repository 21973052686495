import React, { useState, useEffect } from "react";
import { Collapse } from "react-bootstrap";
import ModalExample from "./faqmodal";
import Auth from "./../auth";

const Faq = () => {
  const [showModal, setShowModal] = useState(false);
  const [authUrl, setAuthUrl] = useState("");

  useEffect(() => {
    const authUrl = Auth.getAuth()
      ? global.catalogUrl + "/b2r/SAML/login"
      : global.catalogUrl + "/b2r/landingHome.do?anon=1";
    setAuthUrl(authUrl);
  }, []);

  const questions = [
    {
      id: 1,
      question_title: "How PNC points Works",
      qa: [
        {
          question: "What is PNC points?",
          answer:
            "It is a rewards program that enables you to earn points for purchases with your PNC points Visa card. You can use those points for amazing travel experiences, must-have electronics and gadgets, gift cards to name-brand restaurants and retailers, digital rewards and more!",
        },
        {
          question: "Is there a fee to participate in PNC points?",
          answer: "No, there is no fee for participating in the program.",
        },
      ],
    },
    {
      id: 2,
      question_title: "Eligibility",
      qa: [
        {
          question: "What are the eligibility rules for PNC points?",
          answer:
            "PNC points Visa and PNC Flex® Visa credit cards are auto-enrolled in PNC points. Please refer to the Terms and Conditions for more details.",
        },
      ],
    },
    {
      id: 3,
      question_title: "Earn Points",
      qa: [
        {
          question: "How do I earn points?",
          answer:
            "<div> You shop. You earn points. <br> <br> • Earn points for making <a href='javascript:void(0)' class='purchase'>purchases</a> with your PNC points Visa® or PNC Flex Visa credit cards** <br> <br> • Earn points when you activate and redeem your PNC Purchase Payback offers on the Rewards Center page in Online Banking or in the PNC Mobile Banking app. <br> <br> • Link your eligible personal and business credit cards into one points account and watch your points accumulate even faster! <br> <br>  **PNC points Visa and PNC Flex Visa credit cards are auto-enrolled in PNC points.<br> <br> Please refer to the Terms and Conditions for more details.</div>",
        },
        {
          question: "How do I find my point balance?",
          answer:
            "You can find your PNC points account information on your Rewards Center page in PNC Online Banking or the PNC Mobile Banking app.",
        },
        {
          question: "How quickly will points be credited to my rewards program account?",
          answer:
            "Points for <a href='javascript:void(0)' class='purchase'>purchases</a> will generally be posted to your PNC points account within 90 days.",
        },
        {
          question:
            "I tried to make a purchase with my PNC points Visa or PNC Flex Visa credit card and it was declined.",
          answer:
            "PNC determines whether your card will be accepted when you use it to make a purchase, and your participation in PNC points does not affect card acceptance. Contact us at the number shown on the back of your credit card about your card being declined.",
        },
        {
          question:
            "I've noticed a discrepancy in my PNC points Visa or PNC Flex Visa credit card account purchases and points earned.",
          answer:
            "It may take up to 90 days for some of your points earned from certain purchases to be posted to your PNC points account. This discrepancy may also occur if some of your purchases did not qualify to earn points. For more information on qualifying purchases, please refer to the PNC points Program Terms and Conditions.",
        },
        {
          question: "Is it possible to have a negative points balance?",
          answer:
            "Yes. Returns, credits or charge backs on your PNC points Visa or PNC Flex Visa credit card account will be posted to your PNC points account as negative points. This may cause a negative points balance in your PNC points account until you earn additional points to bring your balance above zero.",
        },
        {
          question: "Do my points ever expire?",
          answer:
            "Unredeemed points expire at the end of the 48th month after the month in which they were posted to your PNC points account. Since you may not be provided with notice of expiration of points, you should check your PNC points account periodically to monitor your points.",
        },
        {
          question: "Why were my points forfeited? How can I get them back?",
          answer:
            "You will forfeit all of your points in your PNC points account if your underlying PNC points Visa or PNC Flex Visa credit card is closed by you or us for any reason, or your participation in PNC points is terminated by you or us for any reason.<br>Once the points are forfeited, you cannot get them back, even if your underlying PNC points Visa or PNC Flex Visa credit card is reinstated or you re-enroll in PNC points. You will not be provided any notice of forfeiture of points in your PNC points account.",
        },
      ],
    },
    {
      id: 4,
      question_title: "Use Points",
      qa: [
        {
          question: "How do I use my points?",
          answer:
            "You can use your program points for rewards either <a href='" +
            authUrl +
            "'>online</a>, or by phone at 1-855-PNC-RWDS (1-855-762-7937). Using points for rewards is similar to shopping online – you just use points to \"pay\" for your reward. Just select the reward you'd like, place it in your online shopping cart and follow the easy instructions to finalize your order. For more information on travel redemption rules please <a href='/docs/ProgramRulesRetail.PDF'>click here</a>.",
        },
        {
          question: "When can I start using points?",
          answer:
            "You can start redeeming the points accrued to your PNC points account as soon as they are posted to your account.",
        },
        {
          question: "How long does it take to receive a reward?",
          answer:
            "Generally, rewards will be sent to you within four weeks of placing your rewards order.<br>Electronic gift cards will be available as soon as you have entered the redemption PIN code on the merchant's website and any other confirmation information required by the merchant.",
        },
        {
          question: "Do gift cards expire?",
          answer:
            "Expiration dates vary depending on the merchant, so please refer to the gift card usage terms provided in the <a href='" +
            authUrl +
            "'>catalog</a> or on the gift card you receive.",
        },
        {
          question: "Can rewards lost or stolen be replaced?",
          answer: "No, we cannot replace lost or stolen rewards.",
        },
        {
          question: "Can rewards be shipped to foreign countries?",
          answer:
            "PNC points only ships rewards within the United States. Rewards are not shipped to U.S. territories such as American Samoa, Guam, Mariana Islands, Puerto Rico and the U.S. Virgin Islands.",
        },
        {
          question:
            "Will I be charged any shipping and handling charges for merchandise and gift cards?",
          answer:
            "No, we do not charge shipping or handling fees for standard delivery of merchandise and gift cards.",
        },
        {
          question: "What happens if a reward I've ordered is out of stock?",
          answer:
            "Although rare, occasionally rewards will be out of stock. You will be sent a back-order notification by mail that will include an estimated ship date. Generally, your rewards order will be sent to you within four to six weeks of placing your rewards order or shipped as soon as the reward is back in stock.",
        },
        {
          question: "Can I return or exchange my reward?",
          answer:
            "No, rewards cannot be returned or exchanged. However, merchandise rewards can be returned to us if they are damaged and you return them within 10 days of your receipt of the reward. Please <a href='/contact'>contact us</a> for return shipping instructions. Certificates/gift cards/digital rewards and other non-merchandise rewards cannot be returned or exchanged.",
        },
      ],
    },
    {
      id: 5,
      question_title: "Account Activity",
      qa: [
        {
          question: "How can I access my PNC points account information?",
          answer:
            "You can access your PNC points account information by visiting the Rewards Center page in Online Banking. This is a simple way to manage your PNC points Account information without any additional login required. Additionally, you can access your PNC points Account information by calling 1-855-PNC-RWDS (1-855-762-7937)",
        },
      ],
    },
    {
      id: 6,
      question_title: "Rewards",
      qa: [
        {
          question: "What types of rewards are available for redemption?",
          answer:
            "You can use points for amazing travel experiences, must-have electronics and gadgets, gift cards to name-brand restaurants and retailers, and more!",
        },
      ],
    },
    {
      id: 7,
      question_title: "Communications",
      qa: [
        {
          question: "How can I get Rewards emails?",
          answer:
            "You can sign up to receive Rewards emails from us. These emails contain key program information, hints on how to earn more and highlight featured redemption options.<br>PNC reserves the right to cancel this email program at any time for any reason.",
        },
        {
          question: "How will you use my email address?",
          answer:
            "We may email you information about any changes in your PNC points account, including profile updates and redemption requests.",
        },
      ],
    },
    {
      id: 8,
      question_title: "Security and Data Use",
      qa: [
        {
          question: "Is redeeming points online secure?",
          answer:
            "We highly value our cardholders' security and have made points redemption on the PNC points website extremely safe. Please read <a href='https://www.pnc.com/en/privacy-policy.html' target='_blank'>privacy policy</a> for more information.",
        },
        {
          question: "What are cookies and does the PNC points website use them?",
          answer:
            "A cookie is a small data file that contains a unique identifier. Cookies can be used to store information about you and your web server session on your hard drive. Cookies can also be used to link your computer to information stored in a separate location. See <a href='https://www.pnc.com/en/privacy-policy.html' target='_blank'>privacy policy</a> for a description of the PNC points program use of cookies.",
        },
        {
          question: "How do I know the emails I get from PNC points are legitimate?",
          answer:
            "PNC points will send you emails for your PNC points account that will include PNC Rewards in the sender's name. We will NEVER ask you for personal information or to verify anything about your PNC points Visa or PNC Flex Visa credit card account by email. Requests like these are often scams and PNC and Visa take great care to avoid sending any email that could be confused with these scams. If you receive an email purporting to be from PNC that you believe is not legitimate, please forward it to abuse@pnc.com.",
        },
        {
          question: "Why do I need to set up security questions?",
          answer:
            "Security questions are another way to verify who you are. For example, security questions allow you to change sensitive profile information such as your PNC points Username or password without having to <a href='/contact'>contact us.</a>",
        },
        {
          question: "What if I cannot remember the answer to my security questions?",
          answer:
            "If you do not answer your security questions correctly or cannot remember the answers, you will need to <a href='/contact'>contact us.</a>",
        },
      ],
    },
    {
      id: 9,
      question_title: "Travel Information",
      qa: [
        {
          question: "Can I book directly with an airline or any other travel supplier?",
          answer:
            "No. Your PNC points travel redemptions can be used only for bookings made directly through the PNC points travel website or by contacting us.",
        },
        {
          question: "May I redeem my points online?",
          answer: "Yes. All travel products can be either redeemed or requested online.",
        },
        {
          question: "Are there any fee(s) associated with travel redemptions?",
          answer:
            "Fees apply for travel booked using the phone with a PNC Travel Specialist. If you would like more information about fees, charges, costs, and taxes, please <a href='/contact'>contact us.</a>",
        },
        {
          question: "Can I pay redemption fee(s) with points?",
          answer:
            "Yes. As you go through the redemption process you will be offered the opportunity to pay your redemption fee using points, as applicable.",
        },
        {
          question: "Can I use my points for someone else to travel?",
          answer:
            "Yes. The PNC points participating cardholder may make reservations in the name of the person of his/her choice. Please ensure that the traveler information you enter during the booking process is an exact match to the travel documents that are required to fulfill the reservation (i.e. driver’s license, passport, etc.). Errors may result in delays and/or possible denial of the travel reservation.",
        },
        {
          question: "I did not get my email confirmation, what should I do?",
          answer:
            "To ensure receipt of your travel confirmation email, please ensure that the email address you provide at the time of booking is a current and valid email address. You may also want to check your spam/junk folder if you have not added us to your safe senders list. If you still do not find your email confirmation, please <a href='/contact'>contact us.</a>",
        },
        {
          question:
            "May I combine or transfer points to/from an airline, hotel or car rental frequent traveler program?",
          answer:
            "No. You may not transfer points from PNC points to another reward program or combine points from PNC points with points from another rewards program.",
        },
        {
          question:
            "Can I earn frequent flyer miles or frequent traveler miles for my PNC points reward redemption?",
          answer:
            "Depending on the travel provider, you might accrue additional points in their program as a result of your PNC points reward redemption. Please check with the individual provider or <a href='/contact'>contact us.</a>",
        },
        {
          question: "Do you offer any insurance for cancelation or trip interruption?",
          answer: "Yes. Please <a href='/contact'>contact us</a> for a quick quote.",
        },
        {
          question:
            "I have to cancel my reservation. Can I have my points reinstated or obtain a refund?",
          answer:
            "No, once redeemed, points for canceled reservations may not be reinstated to your points account. No credit will be given for unused portions of the reward. However, if trip insurance was purchased for the reward, refunds for covered cancelations may be available. Please <a href='/contact'>contact us</a> for additional questions.",
        },
        {
          question:
            "I have to cancel my reservation. Can I get the charges that were made to my card for the travel refunded?",
          answer:
            "No, once the reservation has been made for the travel reward, any card charges for that reward cannot be refunded to you. No credit will be given for unused portions of the reward. However, if trip insurance was purchased and charged to your card for the reward, a refund of that card charge for covered cancelations may be available. Please contact us for additional questions.",
        },
        {
          question: 'What is "split pay" (points and dollars)?',
          answer:
            'Where available, the PNC points "split pay" feature allows you to redeem your travel reward with a combination of points and a debit, credit or prepaid card payment. You are required to use the minimum number of points, as specified by PNC points and, once the minimum point requirement has been met, the remaining cost of the travel reward can be paid for either with additional points or with a credit, debit or prepaid card payment. The travel reward can also be redeemed in full using only points.',
        },
        {
          question:
            'Can I use a card other than the card I have enrolled in PNC points for "split pay" (points and dollars) redemption?',
          answer:
            'Yes, you can use any debit, credit or prepaid card for a "split pay" reward redemption.',
        },
      ],
    },
    {
      id: 10,
      question_title: "Customer Service",
      qa: [
        {
          question: "What happens if my card is lost or stolen?",
          answer:
            "If your card is lost or stolen, contact PNC at 1-855-762-7937 (1-855-PNC-RWDS). If PNC provides you with a replacement card, you will not lose the points in your PNC points account and you will continue to earn points, even if that replacement card has a different card number.",
        },
        {
          question: "How can I get additional information about PNC points?",
          answer:
            "For more information about PNC points, please refer to <a target='_blank' href='https://www.pnc.com/points'>pnc.com/points</a> or to the PNC points <a target='_blank' href='https://www.pnc.com/content/dam/pnc-com/pdf/personal/CreditCards/points-rewards.pdf'>terms and conditions</a>.",
        },
        {
          question: "Why isn't my browser working with your site?",
          answer:
            "To access information online, you need an Internet browser (Microsoft® Internet Explorer version 8.0 or higher, Firefox version 14.0 or higher, Safari version 6.0 or higher) supporting 128-bit encryption and an email account. If you use an older version of one of these browsers (including any versions of Netscape Navigator and AOL®), you could experience technical difficulties with activities on the site. You may download the latest versions of these browsers from the links below:<br><br> <a href='http://windows.microsoft.com/en-US/internet-explorer/downloads/ie' target='_blank'>Download Internet Explorer</a><br> <a href='http://www.mozilla.org/en-US/firefox/update/' target='_blank'>Download Firefox</a><br> <a href='http://www.macupdate.com/app/mac/15675/apple-safari' target='_blank'>Download Safari</a><br><br> For specific information on enabling or disabling your pop-up messages, please see the help section on your browser.<br> If you continue experiencing problems, please <a href='/contact'>contact us.</a>",
        },
      ],
    },
  ];

  const handlePurchaseClick = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const updateModalState = () => {
    setShowModal(false);
  };

  return (
    <section className="pnc-faq pnc-profile">
      <div className="container">
        <div className="row">
          <div className="offset-md-2 col-md-8">
            <div className="faq">
              <h3 aria-level="1">Frequently Asked Questions</h3>
              <div>
                {questions.map((question) => (
                  <QuestionItem
                    key={question.id}
                    {...question}
                    onPurchaseClick={handlePurchaseClick}
                  />
                ))}
              </div>
              {showModal && <ModalExample currSt={showModal} updateSt={updateModalState} />}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const QuestionItem = ({ question_title, qa, onPurchaseClick }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="collapse-wrapper">
      <div onClick={() => setOpen(!isOpen)} className={`faq-title ${isOpen ? "expanded" : ""}`}>
        {question_title}
      </div>
      <Collapse in={isOpen}>
        <div>
          <div className="faq-content">
            {qa &&
              qa.map((item, index) => (
                <div className="qa-wrapper" key={index}>
                  <div className="question">{item.question}</div>
                  <div
                    className="answer"
                    dangerouslySetInnerHTML={{ __html: item.answer }}
                    onClick={(e) => {
                      if (e.target.className === "purchase") {
                        onPurchaseClick(e);
                      }
                    }}
                  />
                </div>
              ))}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default Faq;
