import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { delete_cookie, bake_cookie } from "../read_cookie";

const Redirect = (props) => {
  let params = useParams();
  let navigate = useNavigate();

   // Clear cookies
   delete_cookie("pnc-rewards-token");
   delete_cookie("profileId");
   delete_cookie("ProgramCode");

   let fullEncStr = params.id;

   if (fullEncStr && fullEncStr !== "0") {
     let encArr = fullEncStr.split("&&");
     let token = encArr[0];
     let profileId = encArr[1];
     let ProgramCode = encArr[2];

     // Set cookies
     bake_cookie("pnc-rewards-token", token);
     bake_cookie("profileId", profileId);
     bake_cookie("ProgramCode", ProgramCode);
   }

  useEffect(() => {
    // Perform navigation after all actions
    navigate(`/`);
  }, [params, navigate]);

  return (
    <section className="pnc-help-section">
      <div className="loader"></div>
    </section>
  );
};

export default Redirect;
