import { useState, useEffect } from 'react';

// Custom hook: useFormattedDate
export const useFormattedDate = (inputDate, yearsToSubtract = 0) => {
  const [formattedDate, setFormattedDate] = useState('');

  useEffect(() => {
    if (inputDate) {
      const dateInstance = new Date(inputDate);

      // substract the years if years to substract is more than 0
      if (yearsToSubtract > 0) {
        dateInstance.setUTCFullYear(dateInstance.getUTCFullYear() - yearsToSubtract);
      }

      const year = dateInstance.getUTCFullYear();
      const month = String(dateInstance.getUTCMonth() + 1).padStart(2, '0');
      const day = String(dateInstance.getUTCDate()).padStart(2, '0');

      const formattedDateString = `${month}/${day}/${year}`;
      setFormattedDate(formattedDateString);
    }
  }, [inputDate, yearsToSubtract]);

  return formattedDate;
};
