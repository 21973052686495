import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import data from "./additionalBenefits.json";
import DOMPurify from "dompurify";

import "./additionalBenefits.css";

const AdditionalBenefits = () => {
  return (
    <>
      <Container className="pnc-additional-benefits-section" role="region" aria-labelledby="additional-benefits-title">
        <Row className="pnc-additional-benefits-title-row">
          <h2 className="pnc-additional-benefits-title" id="additional-benefits-title">{data.sectionTitle}</h2>
        </Row>
        <div role="list" className="pnc-additional-benefits-list">
        {data.benefits
          ? data.benefits.map((benefit, index) => {
              return (
                <Row className="pnc-additional-benefits-subtitle-row" key={index} role="listitem">
                  <Col className="pnc-additional-benefits-subtitle">
                    <a href={DOMPurify.sanitize(benefit.titleUrl)} aria-label={`Link to ${benefit.subTitle}`} target="_blank">{benefit.subTitle}</a>
                  </Col>
                  <Col className="pnc-additional-benefits-desc">
                    <p>{benefit.desc}</p>
                  </Col>
                </Row>
              );
            })
          : false}
        </div>
      </Container>
    </>
  );
};

export default AdditionalBenefits;
