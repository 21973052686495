import React from "react";
import Card from "react-bootstrap/Card";
import "./rewardsBenefits.css";
import data from "./rewardsBenefits.json";
import DOMPurify from "dompurify";

const RewardsBenefits = () => {
  const text = data[0];
  const cardData = data[1];
  return (
    <section
      className="pnc-rewards-benefits"
      aria-labelledby="pnc-rewards-benefits-section-heading"
    >
      <div className="container">
        <div className="row">
          <div className="col-12 pb-4">
            <div id="pnc-rewards-benefits-section-heading">{text.Heading}</div>
          </div>
        </div>
        <div className="cards-container" role="list">
          {cardData.map(
            (
              { ImagePath, AltText, Rewards, Heading, Text, CTAText, CTALink },
              index
            ) => (
              <div className="card-wrapper" key={index} role="listitem">
                <Card
                  className="flex-fill"
                  tabIndex="0"
                  aria-label={`Reward card for ${Rewards}`}
                >
                  <Card.Img variant="top" src={DOMPurify.sanitize(ImagePath)} alt={AltText} />
                  <Card.Body className="d-flex justify-content-between flex-column">
                    <div>
                      <Card.Title className="pnc-benefits-card-title">
                        <span>{Rewards}</span> {Heading}
                      </Card.Title>
                      <Card.Text>{Text}</Card.Text>
                    </div>
                    <a
                      href={DOMPurify.sanitize(CTALink)}
                      className="btn btn-primary w-100 pnc-rewards-summary-btn"
                      role="button"
                      target="_blank"
                    >
                      {CTAText}
                    </a>
                  </Card.Body>
                </Card>
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default RewardsBenefits;
