import React, { useEffect, useState } from "react";
import CarouselSlider from "react-bootstrap/Carousel";
import "./rewardsCarousel.css";
import data from "./rewardsCarousel.json";
import DOMPurify from "dompurify";

const RewardsCarousel = () => {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <section className="pnc-carousel">
      <div className="container">
        <CarouselSlider
          activeIndex={index}
          onSelect={handleSelect}
          fade
          slide={false}
          indicators={data.length > 1}
          controls={data.length > 1}
          className={data.length > 1 ? "pnc-mb-50" : ""}
        >
          {data.map((item, index) => (
            <CarouselSlider.Item key={index}>
              <img
                className="pnc-carousel-image"
                src={DOMPurify.sanitize(item.ImagePath)}
                alt={item.AltText}
              />
              <CarouselSlider.Caption>
                <div>
                  <h3 className="pnc-carousel-subtitle">{item.Title}</h3>
                  <p className="pnc-carousel-body">{item.Text}</p>
                  <p>
                    <a
                      className="pnc-carousel-cta"
                      href={DOMPurify.sanitize(item.CTALink)}
                      role="button"
                      target="_blank"
                    >
                      {item.CTAText}
                    </a>
                  </p>
                </div>
              </CarouselSlider.Caption>
            </CarouselSlider.Item>
          ))}
        </CarouselSlider>
      </div>
    </section>
  );
};

export default RewardsCarousel;
