import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useStoreState } from 'easy-peasy';

const BonusPoint = () => {

    //const getUserValue = useStoreState(state => state.user.userInfo);
    //const authuser = getUserValue.hasOwnProperty('FirstName') ? true : false;
    const authuser="";
    const carouselSlideOnClick = (e) => {
        e.preventDefault();
    }
    
    return (
        <section className="pnc-bonus-points">

            <div className="container">
                <div className="row">
                    <div className="col-md-12">

                        <div id="slide-wrapper" className="carousel slide" data-bs-ride="carousel">

                            <Carousel indicators={false}>
                                <Carousel.Item>
                                    {/* <a href={authuser ? global.catalogUrl+"/b2r/SAML/login?supplier=Apple" : global.catalogUrl+"/b2r/landingHome.do?supplier=Apple&anon=1"}> */}
                                    <a 
                                        href={authuser ? global.catalogUrl+"/b2r/SAML/login?supplier=Apple" : ""}
                                        onClick={authuser ? null : carouselSlideOnClick}
                                    > 
                                        <img className="img-responsive hide_mobile" src={`${authuser ? "/images/slide1.png" : "/images/slide1_unauth.png"}`}
                                            alt="placeholder" />
                                        <img className="img-responsive show_mobile" src={`${authuser ? "/images/slide1.png" : "/images/slide1_unauth.png"}`}
                                            alt="placeholder" />
                                    </a>
                                </Carousel.Item>
                                <Carousel.Item>
                                    {/* <a href={authuser ? global.catalogUrl+"/b2r/SAML/login?supplier=Merchandise" : global.catalogUrl+"/b2r/landingHome.do?supplier=Merchandise&anon=1"}> */}
                                    <a 
                                        href={authuser ? global.catalogUrl+"/b2r/SAML/login?supplier=Merchandise" : ""}
                                        onClick={authuser ? null : carouselSlideOnClick}
                                    >
                                        <img className="img-responsive hide_mobile" src={`${authuser ? "/images/slide2.png" : "/images/slide2_unauth.png"}`}
                                            alt="placeholder" />
                                        <img className="img-responsive show_mobile" src={`${authuser ? "/images/slide2.png" : "/images/slide2_unauth.png"}`}
                                            alt="placeholder" />
                                    </a>
                                </Carousel.Item>
                                {/* <Carousel.Item>
                                    <a href="#">
                                        <img className="img-responsive hide_mobile" src="https://place-hold.it/614x200"
                                            alt="placeholder" />
                                        <img className="img-responsive show_mobile" src="https://place-hold.it/350x400"
                                            alt="placeholder" />
                                    </a>
                                </Carousel.Item> */}
                            </Carousel>
                        </div>


                    </div>
                </div>
            </div>

        </section>
    )
}

export default BonusPoint;